import { get, defaultHeaderParams } from "../../common/services/NetService";
import config from "../../common/services/configurationService";
import { AUDIT_URL } from "../../common/services/url";
import axios from 'axios';

export const getAudits = async () =>
  await get(`${config.URL}/${AUDIT_URL}/list`);


export const downloadAudit = async (id) =>
  await axios.get(
    `${config.URL}/${AUDIT_URL}/download_audit/${id}`, {
      responseType: 'blob',
      headers: {...defaultHeaderParams}
    });
