import React, {useEffect, useState}  from 'react'
import {Link} from "react-router-dom";
import ProductListItem from '../../ProductList/components/ProductListItem'
import {getFeaturedProductsList} from "../services/mainServices";

const Products = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {getProducts()}, []);

  const getProducts = async () => {
    let productsResult = await getFeaturedProductsList();
    if (productsResult) {
      setFeaturedProducts(productsResult.data)
    }
  };
  return (
    <div className="landing-page-products-loadmore">
      <div className="wrapper">
        <header className="section-header style_3">
          <h2>Препараты</h2>
        </header>
        <div className="grid">
          <ul>
            {featuredProducts.map((product) =>
              <li key={product.name}>
                <div className="grid-element grid__item one-quarter">
                  <ProductListItem
                    image={product.product_image_url}
                    slug={product.slug}
                    name={product.name}
                    international_name={product.international_name}
                    release_form={product.release_form}
                    pharmacotherapeutic_group={product.pharmacotherapeutic_group}
                  />
                </div>
              </li>
            )}
          </ul>
      </div>
        <div className="main-page-product-button">
            <span className="loadmore btn">
                <Link to="/productlist">Все препараты</Link>
            </span></div>
      </div>
    </div>
  );
};

export default Products

