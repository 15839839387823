import React from 'react';
import PricetListHeader from "./PriceListHeader"
import {downloadPriceList} from "../services/priceListServices";

let fileDownload = require('js-file-download');

const PriceListSearch = ({onFilterChange}) => {
  const handleNameChange = e => {
    onFilterChange(e.target.value);
  };

  const getPriceList = () =>{
    downloadPriceList()
      .then(res => fileDownload(res.data, 'PriceArgo.xls'))
      .catch(err => console.log("Error during instruction download: " + err))
  };

  return (
    <div className="collection-toolbar">
      <div className = "collection-actions">
        <div className="collection-label">
          <div className="price_list_download" onClick={getPriceList}>Скачать Прайс-лист</div>
        </div>
        <div className="collection-search">
          <div className="collection-filter">
            <input placeholder='Введите название препарата' className='collection-name-search'
                   onKeyPress={(e) => e.key === 'Enter' ? handleNameChange(e) : null}/>
          </div>
        </div>
      </div>
      <div>
        <PricetListHeader/>
      </div>
    </div>
  )
};

export default PriceListSearch;