import React, {useEffect, useState} from 'react';
import {withRouter, Link} from "react-router-dom";
import {getAudits, downloadAudit} from "./services/audittService";

let fileDownload = require('js-file-download');

const AboutUs = () => {
  const [audits, setAudits] = useState([]);

  useEffect(() => {
    async function getAllAudits() {
      const results = await getAudits();
      setAudits(results.data);
    }
    getAllAudits();
  }, []);

  const downloadSpecificAudit = (id, file_name) => {
    downloadAudit(id)
      .then(res => fileDownload(res.data, file_name))
      .catch(err => console.log("Error during audit download: " + err))
  };

  return (
    <main className="main-content" style={{marginTop: "20px"}}>
      <div id="html-section-product-template" className="html-section">
        <div className="wrapper">
          <div className="grid">
            <div className="grid__item">
              <div className="grid__item">
                <div className="about-us-item">
                  <div className="section-header style_3">
                    <h2>История компании</h2>
                  </div>
                  <p>
                    Фармацевтическая компания <strong>«Арго-Фарм»</strong> была основана в 1997 году.
                    В настоящее время она занимает ведущее положение на фармацевтическом рынке Армении.
                    Компания уже около 20-и лет успешно занимается импортом и
                    оптовой торговлей фармацевтической продукции.
                  </p>
                  <div className="section-header style_3">
                    <h2>Партнеры</h2>
                  </div>
                  <p>
                    Партнерами <strong>«Арго-Фарм»</strong> на протяжении долгих лет являются компании <strong>«Рекитт
                    Бензикер», «Фармстандарт», «ОТИСИФАРМ», «Биокад», «Валентафарм»,
                    «Петровакс», «Биосинтез», «Хелп», «Джи Пи Фарма», «Хемигруп»․</strong>
                  </p>
                  <div className="section-header style_3">
                    <h2>Миссия компании</h2>
                  </div>
                  <p>
                    Компания обеспечивает гарантированное качество поступающей напрямую от производителей
                    и официальных дистрибьюторов сертифицированной и прошедшей контроль качества со стороны
                    МЗРА продукции.
                  </p>
                  <p>
                    Компания <strong>«Арго-Фарм»</strong> предлагает широкий ассортимент продукции:
                  </p>
                  <div className="product-single__description rte" itemProp="description">
                    <div className="product-description-item">
                      <div className="oval oval-product-description"></div>
                      <div className="prduct-description-item-text">лекарственные средства</div>
                    </div>
                    <div className="product-description-item">
                      <div className="oval oval-product-description"></div>
                      <div className="prduct-description-item-text">предметы медицинского назначения и ухода за больными</div>
                    </div>
                    <div className="product-description-item">
                      <div className="oval oval-product-description"></div>
                      <div className="prduct-description-item-text">предметы гигиены</div>
                    </div>
                    <div className="product-description-item">
                      <div className="oval oval-product-description"></div>
                      <div className="prduct-description-item-text">высококачественная
                        косметика из Германии и детское питание</div>
                    </div>
                  </div>
                  <p>
                    Эту продукцию можно приобрести как в аптеках собственной
                    аптечной сети «36,6», так и в других аптеках РА.
                  </p>
                    <div className="section-header style_3">
                      <h2>Аудит</h2>
                    </div>
                    <div className="product-single__description rte" itemProp="description">
                      {
                        audits.map(audit => {
                          return <div className="product-description-item">
                            <div className="oval oval-product-description"></div>
                            <div className="audit-item prduct-description-item-text" onClick={() => downloadSpecificAudit(audit.id, audit.audit_report)}>{audit.title}</div>
                          </div>
                        })}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default withRouter(AboutUs);