import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import SaleListItem from './Components/SaleListItem'
import InfiniteScroll from 'react-infinite-scroller';
import {get} from "../common/services/NetService";
import {getSaleList} from "./services/saleListService";
import Loader from '../common/components/Loader'

class SalesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      blogs: [],
      hasMoreItems: true,
      nextHref: null,
    };
  }

  async loadBlogs(page) {
    let res = null;
    if (!this.state.nextHref && this.state.hasMoreItems) {
      res = await getSaleList();
    } else if (this.state.nextHref) {
      res = await get(`${this.state.nextHref}`);
    }
    if (res) {
      let tracks = this.state.blogs;
      res.data.results.forEach((blog) => {
        tracks.push(blog);
      });

      if (res.data.next) {
        this.setState({
          blogs: tracks,
          nextHref: res.data.next
        });
      } else {
        this.setState({
          hasMoreItems: false
        });
      }
    }

  }

  render() {
    let blogs = [];
    this.state.blogs.map((blog) => {
      blogs.push(
        <div className="grid-element large--one-third">
          <SaleListItem slug={blog.slug}
                        image={blog.image_url}
                        title={blog.title}
                        start_date={blog.start_date}
                        end_date={blog.end_date}
                        description={blog.description.replace(/<[^>]*>/g, '')}
          />
        </div>
      );
    });
    return (
      <main className="main-content">
        <header className="blog-header section-header style_3">
          <h2 className="section-title">Акции</h2>
        </header>
        <div id="html-section-blog-template" className="html-section">
          <div className="wrapper article-grid">
            <div className="blogs-slider home-blog-wrapper">
              <div className="grid--rev blog_list">
                <div className="grid-uniform grid-uniform-category">
                  <div className="grid__item large--one-whole">
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.loadBlogs.bind(this)}
                      hasMore={this.state.hasMoreItems}
                      loader={Loader()}>
                      {blogs}
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );

  }
}

export default withRouter(SalesList);