const PRODUCT_URL = "ru/product";
const SLIDES_URL = "ru/slide";
const PARTNER_URL = "ru/partner";
const BLOG_URL = "ru/blog";
const SALE_URL = "ru/sale";
const SEND_MAIL = "ru/send_mail";
const AUDIT_URL = "ru/audit";

export {
  PRODUCT_URL,
  SLIDES_URL,
  PARTNER_URL,
  BLOG_URL,
  SALE_URL,
  SEND_MAIL,
  AUDIT_URL
};
