import React, {useEffect, useState} from 'react'
import {getPartnersList} from "../services/mainServices";
import '../../assets/css/modal.css'


const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState({});

  useEffect(() => {getPartners()}, []);

  const getPartners = async () => {
    let partnersResult = await getPartnersList();
    if (partnersResult) {
      setPartners(partnersResult.data)
    }
  };

  const openDialog = (partner) => {
    setSelectedPartner(partner);
    setShowModal(true);
  }

  return (
    <div className="html-section index-section text-info"><div style={{backgroundColor: '#fafafa'}}>
      <div className="wrapper">
        <div className="text-information" data-section-id={1494307708073} style={{paddingTop: '60px', paddingBottom: '60px'}}>
          <div className="home-content">
            <div className="section-header style_3">
              <h2>Наши Партнеры</h2>
            </div>
            <div className="text-info-content">
              {partners.map((partner) =>
                <div className="info-1 grid__item one-third" key={partner.name}>
                  <div className="info-group" style={{backgroundColor: ''}}>
                    <div className="info-icon">
                      <img src={partner.logo_url} alt={partner.name} />
                    </div>
                    <div className="info-text">
                      <span className="h4">{partner.name}</span>
                      <span className="h5">{partner.description_short}</span>
                      <div className="partnersMore" onClick={() => openDialog(partner)}>Подробнее <span className="icon icon-arrow-right" aria-hidden="true" /></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {
         showModal && selectedPartner.name ? (
           <div id="myModal" className="modal">
             <div className="modal-content">
               <span className="close" onClick={() => setShowModal(false)}>&times;</span>
               <div className="info-icon">
                 <img src={selectedPartner.logo_url} alt={selectedPartner.name} />
               </div>
               <div className="modal-info-text">
                 <div className="slide-description" dangerouslySetInnerHTML={{ __html: selectedPartner.description_long }} />
               </div>
             </div>
           </div>
         ) : ""
      }
    </div>
    </div>
  );
};

export default Partners;
