import React from 'react';
import { withRouter, Link } from "react-router-dom";
import {sendEmail} from "./services/sendEmailService";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ButtonLoader from '../assets/images/button-loader.gif'


const DrugSafety = () => {
  const [contactInfo, setContactInfo] = React.useState({
    email: '',
    name: '',
    subject: '',
    content: '',
    phone: '',
  });
  const [loader, setLoader] = React.useState(false);
  const [contactInfoValidattion, setContactInfoValidattion] = React.useState({});

  let sendContactEmail = async () => {
    setContactInfoValidattion({});
    setLoader(true);
    sendEmail(contactInfo)
      .then((res) => {
        setLoader(false);
        if (res['statusCode'] === 200) {
          NotificationManager.success('Письмо успешно отправлено.', 'Письмо отправлено');
        }
      })
      .catch((err) => {
        setLoader(false);
        let errorFields = JSON.parse(err['message']);
        setContactInfoValidattion(errorFields);
      })
  };

  return (
      <main className="main-content">
        <header className="blog-header section-header style_3">
          <h2 className="section-title">Сообщить о развитии нежелательной реакции</h2>
        </header>
      <div className="html-section index-section blogs-slider">
        <div className="wrapper">
          <div className="grid">
              <div className="form-vertical grid__item">
                {
                  contactInfoValidattion['email'] ?
                  contactInfoValidattion['email'] === 'blank' ?
                  <div className="contact-form-required-labels">Эл. адрес - обязательное поле.</div> :
                    <div className="contact-form-required-labels">Эл. адрес - введите корректный адрес.</div>
                    : null
                }
                <div className="grid grid--small">
                  <div className="grid__item large--one-half">
                    <input type="email" className="email-field input-full"
                           name="contact[email]" placeholder="Эл. адрес"
                           autoCorrect="off" autoCapitalize="off"
                           onChange={(e) => setContactInfo({...contactInfo, email: e.target.value})}/>
                  </div>
                  <div className="grid__item large--one-half">
                    <input type="tel" className="email-field input-full"
                           name="contact[phone]" placeholder="Телефон"
                           onChange={(e) => setContactInfo({...contactInfo, phone: e.target.value})}/>
                  </div>
                </div>
                {
                  contactInfoValidattion['name'] ?
                    <div className="contact-form-required-labels">Имя - обязательное поле.</div> : null
                }
                <input type="text" className="email-field input-full"
                       name="contact[name]" placeholder="Имя"
                       autoCapitalize="words"
                       onChange={(e) => setContactInfo({...contactInfo, name: e.target.value})}/>
                {
                  contactInfoValidattion['subject'] ?
                    <div className="contact-form-required-labels">Тема - обязательное поле.</div> : null
                }
                <input type="text" className="email-field input-full"
                       name="contact[theme]" placeholder="Тема"
                       autoCapitalize="words"
                       onChange={(e) => setContactInfo({...contactInfo, subject: e.target.value})}/>
                {
                  contactInfoValidattion['content'] ?
                    <div className="contact-form-required-labels">Сообщение - обязательное поле.</div> : null
                }
                <textarea rows={5} className="email-field input-full"
                          name="contact[body]"
                          placeholder="Сообщение" defaultValue={""}
                          onChange={(e) => setContactInfo({...contactInfo, content: e.target.value})}/>
                <span className="email-send product-catalog-view-button loadmore btn" onClick={sendContactEmail}>
                  <div>
                    {loader ? null : <div>Отправить электронное письмо</div>}
                    {loader ? <img src={ButtonLoader} style={{width: '20px', height: '20px'}}/> : null}
                  </div>
                </span>
                <NotificationContainer/>
              </div>
          </div>
        </div>
        </div>
      </main>
  );
}

export default withRouter(DrugSafety);