import React from 'react';
import { Link } from "react-router-dom";

const SaleListItem = ({
                        slug,
                        image,
                        title,
                        start_date,
                        end_date,
                        description
                      }) => {
  return (
    <div className="grid__item">
      <div className="blog-top">
        <img src={image} alt="" />
      </div>
      <div className="blog-bottom">
        <div className="blog-title clearfix">{title}</div>
        <ul className="information">
          <li className="date"><time dateTime={start_date}>{start_date}</time></li>
          <li className="date">&nbsp;-&nbsp;</li>
          <li className="date"><time dateTime={end_date}>{end_date}</time></li>
        </ul>
        <div className="blog-desc">
          {description.substring(0, 150).concat(description.length > 150 ? "..." : "")}
        </div>
        <p>
          <Link to={`/sale/${slug}`} className="text-link">Подробнее <span className="icon icon-arrow-right" aria-hidden="true" /></Link>
        </p>
      </div>
    </div>
  );
}

export default SaleListItem;