import React, {useEffect, useState} from 'react';
import RelatedProducts from './components/RelatedProducts'
import {withRouter} from "react-router-dom";
import {getProduct, getPartner, getProductInstruction, getRelatedProducts} from "./services/productService";

let fileDownload = require('js-file-download');

const Product = (props) => {
  const [product, setProduct] = useState(null);
  const [partner, setPartner] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState(null);
  const {
    match: {
      params: {slug},
    },
  } = props;

  useEffect(() => {
    async function getProductBySlug() {
      const product = await getProduct(slug);
      setProduct(product);
      const partner = await getPartner(product.data.pharma_company_id);
      setPartner(partner);
      const products = await getRelatedProducts(slug);
      setRelatedProducts(products);
    }
    getProductBySlug()
  }, []);

  const downloadInstruction = (file_name) => {
    getProductInstruction(slug)
      .then(res => fileDownload(res.data, file_name))
      .catch(err => console.log("Error during instruction download: " + err))
  };

  if (product) {
    return (
      <div className="wrapper">
        <div className="grid product-single">
          <div className="product-main-wrapper">
            <div className="product-description-wrapper1">
              <div className="single-product-name">{product.data.name}</div>
              <div><span className="loadmore btn" onClick={() => downloadInstruction(product.data.instruction)}>Инструкция препарата </span></div>
              <img src={product.data.product_image_url} className="single-product-image" alt={product.data.name} id="ProductPhotoImg"/>
            </div>
            <div className="product-description-wrapper2">
                <div className="product-detailed-description">
                  <div className="product-page-headers">Основное описание</div>
                  <div className="product-single__description rte" itemProp="description">
                    <div className="product-description-item">
                      <div className="oval oval-product-description"></div>
                      <div className="prduct-description-item-text">
                        Международное непатентованное название: <b>{product.data.international_name}</b></div>
                    </div>
                    <div className="product-description-item">
                      <div className="oval oval-product-description"></div>
                      <div className="prduct-description-item-text">
                        Фармакотерапевтическая группа: <b>{product.data.pharmacotherapeutic_group}</b></div>
                    </div>
                    <div className="product-description-item">
                      <div className="oval oval-product-description"></div>
                      <div className="prduct-description-item-text">
                        Форма выпуска: <b>{product.data.release_form}</b></div>
                    </div>
                    <div className="product-description-item">
                      <div className="oval oval-product-description"></div>
                      <div className="prduct-description-item-text">
                        Условия отпуска из аптек: <b>{product.data.dispensing_no_recipe_text}</b></div>
                    </div>
                </div>
              </div>
              <div className="product-manufacturer-description">
                <div className="product-page-headers">Производитель</div>
                {
                  partner ? (
                    <>
                    <img src={partner.data.logo_url} className="product-manufactor-image" alt={partner.data.name}/>
                      <div className="product-manufactor-description">{partner.data.description_short}</div>
                      </>
                    ) : null
                }
              </div>
            </div>
          </div>
          <div className="product-additional-information">
            <div className="product-additional-information-name">Похожие препараты</div>
            <div className="product-related-products">
              <RelatedProducts products={relatedProducts ? relatedProducts.data.results: []}/>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default withRouter(Product);