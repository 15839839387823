import { get, defaultHeaderParams } from "../../common/services/NetService";
import config from "../../common/services/configurationService";
import { PRODUCT_URL, PARTNER_URL } from "../../common/services/url";
import axios from 'axios';

const DOWNLOAD_INSTRUCTION = 'download_instruction';
const RELATED_PRODUCTS = 'related_products';

export const getProduct = async (slug) =>
  await get(`${config.URL}/${PRODUCT_URL}/${slug}`);

export const getPartner = async (id) =>
  await get(`${config.URL}/${PARTNER_URL}/${id}`);

export const getProductInstruction = async (slug) =>
  await axios.get(
    `${config.URL}/${PRODUCT_URL}/${DOWNLOAD_INSTRUCTION}/${slug}`, {
      responseType: 'blob',
      headers: {...defaultHeaderParams}
    });

export const getRelatedProducts = async (slug) =>
  await get(`${config.URL}/${PRODUCT_URL}/${RELATED_PRODUCTS}/${slug}`);