import React from 'react';
import { withRouter, Link } from "react-router-dom";

const Account = () => {
  return (
    <main className="main-content">
      <div id="html-section-product-template" className="html-section">
        <header className="section-header wrapper-breadcrumb">
          <div className="wrapper">
            <h3>My Account</h3>
            <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
              <Link to="/" title="Back to the frontpage">Home</Link>
              <span aria-hidden="true"><i className="fa fa-angle-right" aria-hidden="true" /></span>
              <span>My Account</span>
            </nav>
          </div>
        </header>
        <div className="wrapper">
          <div className="grid">
            <div className="grid__item">
              <div className="grid">
                <div className="grid__item two-thirds medium-down--one-whole">
                  <h2 className="sb-title">Order History</h2>
                  <p>
                    You haven't placed any orders yet.
                  </p>
                </div>
                <div className="grid__item one-third medium-down--one-whole">
                  <h3 className="sb-title">Account Details</h3>
                  <p className="h5">
                    Tadathemes (tadathemes@gmail.com)
                  </p>
                  <p>
                    <a href="#" className="text-link">View Addresses</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withRouter(Account);
