import {defaultHeaderParams, get} from "../../common/services/NetService";
import config from "../../common/services/configurationService";
import { PRODUCT_URL } from "../../common/services/url";
import axios from "axios/index";

const DOWNLOAD_PRICE_LIST = 'download_price_list'

export const getPriceList = async (name) => {
  const list = 'price_list?name=';
  return await get(`${config.URL}/${PRODUCT_URL}/${list}${name}`);
};

export const downloadPriceList = async () =>
  await axios.get(
    `${config.URL}/${PRODUCT_URL}/${DOWNLOAD_PRICE_LIST}`, {
      responseType: 'blob',
      headers: {...defaultHeaderParams}
    });
