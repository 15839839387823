import React from 'react';
import "../../assets/css/pricelist.css"


const PricetListItem = ({name, partner, international_name, expire_date, wholesale}) => {
  return (
    <div className="price_list_view_item grid-view-item">
      <div className="price_list_container">
        <div className="price_list_container_cell price_list_cell1">
          {name}
        </div>
        <div className="price_list_container_cell price_list_cell2">
          {partner}
        </div>
        <div className="price_list_container_cell price_list_cell2">
          {international_name}
        </div>
        <div className="price_list_container_cell price_list_cell3">
          {expire_date}
        </div>
        <div className="price_list_container_cell price_list_cell4">
          {wholesale}
        </div>
      </div>
    </div>
  )
};

export default PricetListItem;