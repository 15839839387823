import React from 'react';
import { withRouter, Link } from "react-router-dom";

const Login = () => {
  return (
    <div id="html-section-product-template" className="html-section">
      <header className="section-header wrapper-breadcrumb">
        <div className="wrapper">
          <h3>Account Login</h3>
          <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
            <Link to="/" title="Back to the frontpage">Home</Link>
            <span aria-hidden="true"><i className="fa fa-angle-right" aria-hidden="true" /></span>
            <span>Account Login</span>
          </nav>
        </div>
      </header>
      <div className="grid">
        <div className="grid__item large--one-third push--large--one-third text-center">
          <div id="CustomerLoginForm" className="form-vertical">
            <form method="post" action="#" id="customer_login" acceptCharset="UTF-8">
              <input type="hidden" name="form_type" defaultValue="customer_login" /><input type="hidden" name="utf8" defaultValue="?" />
              <label htmlFor="CustomerEmail" className="hidden-label">Email</label>
              <input type="email" name="customer[email]" id="CustomerEmail" className="input-full" placeholder="Email" autoCorrect="off" autoCapitalize="off" autofocus />
              <label htmlFor="CustomerPassword" className="hidden-label">Password</label>
              <input type="password" defaultValue name="customer[password]" id="CustomerPassword" className="input-full" placeholder="Password" />
              <p>
                <input type="submit" className="btn btn--full" defaultValue="Sign In" />
              </p>
              <p>
                <Link to="/register" id="customer_register_link">Create Account</Link>
              </p>
              <p>
                <Link to="/">Return to Store</Link>
              </p>
              <p>
                <a href="#" id="RecoverPassword">Forgot your password?</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
