import React from 'react';
import { withRouter, Link } from "react-router-dom";
import ProductImage from '../assets/images/product1_320x320.jpg'

function SearchResult() {
  return (
    <main className="main-content">
      <div id="shopify-section-search-template" className="shopify-section">
        <div className="wrapper" style={{paddingTop: 'px', paddingBottom: 'px'}}>
          <div className="grid">
            <div className="grid__item">
              <header className="section-header text-center">
                <h1 className="text-center">Your search for "samsung" revealed the following:</h1>
                <hr className="hr--small"/>
              </header>
              <form action="/search" method="get" className="input-group search-bar search-bar--page" role="search"
                    style={{position: 'relative'}}>
                <input type="search" name="q" defaultValue="samsung" placeholder="Search something"
                       className="input-group-field" aria-label="Search something" autoComplete="off"/>
                <span className="input-group-btn">
                  <button type="submit" className="btn icon-fallback-text">
                    <span className="icon icon-search" aria-hidden="true"/>
                    <span className="fallback-text">Search</span>
                  </button>
                </span>
                <ul className="search-results"
                    style={{position: 'absolute', left: '0px', top: '43px', display: 'none'}}>
                </ul>
              </form>
              <hr className="hr--medium hr--clear"/>
              <div className="grid-uniform">
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Dell Inspiron 7460"/>
                        </Link>
                        <div className="product-label">
                          <div className="label-element deal-label">
                            <span style={{color: '#ffffff', backgroundColor: '#00d5d5'}}>Deal</span>
                          </div>
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33606895942}>Default Title - $899.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button type="submit" name="add" id="AddToCart"
                                        className="btn btn-1 add-to-cart AddToCart" title="Buy Now"><span
                                  id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span></button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="copy-of-copy-of-asus-rog-g752vm" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Dell Inspiron 7460</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9212331974" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <s className="product-price__price"><span className="money" doubly-currency-usd={99900}
                                                                    doubly-currency="USD">$999 USD</span></s>
                          <span className="product-price__price product-price__sale">
                            <span className="money" doubly-currency-usd={89900} doubly-currency="USD">$899 USD</span>
                            <span className="product-price__sale-label">On Sale</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Dell Inspiron 7460</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <s className="product-price__price"><span className="money" doubly-currency-usd={99900}
                                                                      doubly-currency="USD">$999 USD</span></s>
                            <span className="product-price__price product-price__sale">
                              <span className="money" doubly-currency-usd={89900} doubly-currency="USD">$899 USD</span>
                              <span className="product-price__sale-label">On Sale</span>
                            </span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Processor: Core i5 Kabylake 2.50GHz</li>
                              <li>Memory: DDR4 4GB 2400MHz</li>
                              <li>Display: 14.0" 1920x1080</li>
                              <li>Graphic: NVIDIA GeForce 940MX, 2 GB</li>
                              <li>Storage: HDD: 500GB + SSD: 128GB</li>
                              <li>Camera: 1 M</li>
                              <li>Networking: 802.11 b/g/n</li>
                              <li>Battery: Li-Ion 3 cell</li>
                              <li>Dimensions: 323.3 x 227.1 x 18.95 cmM</li>
                              <li>Weight: 1.7 kg</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33606895942}>Default Title - $899.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button type="submit" name="add" id="AddToCart"
                                            className="btn btn-1 AddToCart add-to-cart" title="Buy Now"><span
                                      id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span>
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="copy-of-copy-of-asus-rog-g752vm" className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Dell Inspiron 7460</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9212331974" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <s className="product-price__price"><span className="money" doubly-currency-usd={99900}
                                                                  doubly-currency="USD">$999 USD</span></s>
                        <span className="product-price__price product-price__sale">
                          <span className="money" doubly-currency-usd={89900} doubly-currency="USD">$899 USD</span>
                          <span className="product-price__sale-label">On Sale</span>
                        </span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Processor: Core i5 Kabylake 2.50GHz</li>
                          <li>Memory: DDR4 4GB 2400MHz</li>
                          <li>Display: 14.0" 1920x1080</li>
                          <li>Graphic: NVIDIA GeForce 940MX, 2 GB</li>
                          <li>Storage: HDD: 500GB + SSD: 128GB</li>
                          <li>Camera: 1 M</li>
                          <li>Networking: 802.11 b/g/n</li>
                          <li>Battery: Li-Ion 3 cell</li>
                          <li>Dimensions: 323.3 x 227.1 x 18.95 cmM</li>
                          <li>Weight: 1.7 kg</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Dell Vostro 5468"/>
                        </Link>
                        <div className="product-label">
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33747995462}>Default Title - $879.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button type="submit" name="add" id="AddToCart"
                                        className="btn btn-1 add-to-cart AddToCart" title="Buy Now"><span
                                  id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span></button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="dell-vostro-5468" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Dell Vostro 5468</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9235971782" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product-price__price"><span className="money" doubly-currency-usd={87900}
                                                                       doubly-currency="USD">$879 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Dell Vostro 5468</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product-price__price"><span className="money" doubly-currency-usd={87900}
                                                                         doubly-currency="USD">$879 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Processor: Intel, Core i5 Skylake</li>
                              <li>Memory: DDR4 - 4 GB</li>
                              <li>Display: 14" 1366 x 768</li>
                              <li>Graphic: NVIDIA GeForce 940MX</li>
                              <li>Storage: HDD, 1 TB</li>
                              <li>Camera: 1 MP</li>
                              <li>Networking: 802.11 b/g/n</li>
                              <li>Battery: Li-Ion 3 cell</li>
                              <li>Dimensions: 340 x 240 x 18.5 cmM</li>
                              <li>Weight: 1.66 kg</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33747995462}>Default Title - $879.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button type="submit" name="add" id="AddToCart"
                                            className="btn btn-1 AddToCart add-to-cart" title="Buy Now"><span
                                      id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span>
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="dell-vostro-5468" className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Dell Vostro 5468</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9235971782" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product-price__price"><span className="money" doubly-currency-usd={87900}
                                                                     doubly-currency="USD">$879 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Processor: Intel, Core i5 Skylake</li>
                          <li>Memory: DDR4 - 4 GB</li>
                          <li>Display: 14" 1366 x 768</li>
                          <li>Graphic: NVIDIA GeForce 940MX</li>
                          <li>Storage: HDD, 1 TB</li>
                          <li>Camera: 1 MP</li>
                          <li>Networking: 802.11 b/g/n</li>
                          <li>Battery: Li-Ion 3 cell</li>
                          <li>Dimensions: 340 x 240 x 18.5 cmM</li>
                          <li>Weight: 1.66 kg</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Google Pixel"/>
                        </Link>
                        <div className="product-label">
                          <div className="label-element new-label">
                            <span style={{color: '#ffffff', backgroundColor: '#ffb400'}}>New</span>
                          </div>
                          <div className="label-element trend-label">
                            <span style={{color: '#ffffff', backgroundColor: '#003cff'}}>Trending</span>
                          </div>
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33071043974}>32GB / Quite Black - $834.30</option>
                                  <option value={33071044038}>32GB / Very Silver - $840.20</option>
                                  <option value={33071044102}>32GB / Really Blue - $840.00</option>
                                  <option value={33487510918}>128GB / Really Blue - $1,000.00</option>
                                  <option value={33487513094}>128GB / Very Silver - $1,000.00</option>
                                  <option value={33487520390}>128GB / Quite Black - $1,000.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button className="btn btn-1 select-option" type="button"
                                        onClick="window.location='./product.html';" title="Select Options"><i
                                  className="fa fa-bars"/> Options
                                </button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="cum-sociis-natoque-penatibus-et-magnis-7" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Google Pixel</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9059386182" data-rating={4.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star-empty"/></span><span className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product__price"><span className="money" doubly-currency-usd={83430}
                                                                 doubly-currency="USD">$834 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Google Pixel</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product__price"><span className="money" doubly-currency-usd={83430}
                                                                   doubly-currency="USD">$834 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Technology: GSM / CDMA / HSPA / EVDO / LTE</li>
                              <li>Dimensions: 143.8 x 69.5 x 8.5 mm</li>
                              <li>Weight: 143 g</li>
                              <li>Display: AMOLED 5.0 inches</li>
                              <li>Resolution: 1080 x 1920</li>
                              <li>OS: Android OS, v7.1 (Nougat)</li>
                              <li>Chipset: Snapdragon 821</li>
                              <li>CPU: Quad-core</li>
                              <li>Internal: 32/128 GB, 4 GB RAM</li>
                              <li>Camera: 12.3 MP, f/2.0 - 8 MP, f/2.4</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33071043974}>32GB / Quite Black - $834.30
                                      </option>
                                      <option value={33071044038}>32GB / Very Silver - $840.20</option>
                                      <option value={33071044102}>32GB / Really Blue - $840.00</option>
                                      <option value={33487510918}>128GB / Really Blue - $1,000.00</option>
                                      <option value={33487513094}>128GB / Very Silver - $1,000.00</option>
                                      <option value={33487520390}>128GB / Quite Black - $1,000.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button className="btn btn-1 select-option" type="button"
                                            onClick="window.location='./product.html';" title="Select Options"><i
                                      className="fa fa-bars"/> Options
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="cum-sociis-natoque-penatibus-et-magnis-7"
                                         className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Google Pixel</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9059386182" data-rating={4.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star-empty"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product__price"><span className="money" doubly-currency-usd={83430}
                                                               doubly-currency="USD">$834 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Technology: GSM / CDMA / HSPA / EVDO / LTE</li>
                          <li>Dimensions: 143.8 x 69.5 x 8.5 mm</li>
                          <li>Weight: 143 g</li>
                          <li>Display: AMOLED 5.0 inches</li>
                          <li>Resolution: 1080 x 1920</li>
                          <li>OS: Android OS, v7.1 (Nougat)</li>
                          <li>Chipset: Snapdragon 821</li>
                          <li>CPU: Quad-core</li>
                          <li>Internal: 32/128 GB, 4 GB RAM</li>
                          <li>Camera: 12.3 MP, f/2.0 - 8 MP, f/2.4</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="HP Pavilion 15 AU072TX"/>
                        </Link>
                        <div className="product-label">
                          <div className="label-element deal-label">
                            <span style={{color: '#ffffff', backgroundColor: '#00d5d5'}}>Deal</span>
                          </div>
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33634662406}>Default Title - $799.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button type="submit" name="add" id="AddToCart"
                                        className="btn btn-1 add-to-cart AddToCart" title="Buy Now"><span
                                  id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span></button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="copy-of-copy-of-asus-rog-g752vm-2" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">HP Pavilion 15 AU072TX</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9217085190" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <s className="product-price__price"><span className="money" doubly-currency-usd={84900}
                                                                    doubly-currency="USD">$849 USD</span></s>
                          <span className="product-price__price product-price__sale">
                            <span className="money" doubly-currency-usd={79900} doubly-currency="USD">$799 USD</span>
                            <span className="product-price__sale-label">On Sale</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">HP Pavilion 15 AU072TX</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <s className="product-price__price"><span className="money" doubly-currency-usd={84900}
                                                                      doubly-currency="USD">$849 USD</span></s>
                            <span className="product-price__price product-price__sale">
                              <span className="money" doubly-currency-usd={79900} doubly-currency="USD">$799 USD</span>
                              <span className="product-price__sale-label">On Sale</span>
                            </span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Processor: Intel, Core i7 Skylake</li>
                              <li>Memory: DDR4 4 GB</li>
                              <li>Display: 15.6" - 1366x768</li>
                              <li>Graphic: NVIDIA?GeForce?940M, 4 GB</li>
                              <li>Storage: HDD, 1 TB</li>
                              <li>Camera: 1 MP</li>
                              <li>Networking: 802.11 b/g/n</li>
                              <li>Battery: Li-Ion 2 cell</li>
                              <li>Dimensions: 383 x 243 x 22.5 cmM</li>
                              <li>Weight: 2 kg</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33634662406}>Default Title - $799.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button type="submit" name="add" id="AddToCart"
                                            className="btn btn-1 AddToCart add-to-cart" title="Buy Now"><span
                                      id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span>
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="copy-of-copy-of-asus-rog-g752vm-2" className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">HP Pavilion 15 AU072TX</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9217085190" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <s className="product-price__price"><span className="money" doubly-currency-usd={84900}
                                                                  doubly-currency="USD">$849 USD</span></s>
                        <span className="product-price__price product-price__sale">
                          <span className="money" doubly-currency-usd={79900} doubly-currency="USD">$799 USD</span>
                          <span className="product-price__sale-label">On Sale</span>
                        </span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Processor: Intel, Core i7 Skylake</li>
                          <li>Memory: DDR4 4 GB</li>
                          <li>Display: 15.6" - 1366x768</li>
                          <li>Graphic: NVIDIA?GeForce?940M, 4 GB</li>
                          <li>Storage: HDD, 1 TB</li>
                          <li>Camera: 1 MP</li>
                          <li>Networking: 802.11 b/g/n</li>
                          <li>Battery: Li-Ion 2 cell</li>
                          <li>Dimensions: 383 x 243 x 22.5 cmM</li>
                          <li>Weight: 2 kg</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Apple iPad Air 2"/>
                        </Link>
                        <div className="product-label">
                          <div className="label-element new-label">
                            <span style={{color: '#ffffff', backgroundColor: '#ffb400'}}>New</span>
                          </div>
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button className="btn btn-1 select-option" type="button"
                                        onClick="window.location='./product.html';" title="Select Options"><i
                                  className="fa fa-bars"/> Options
                                </button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="etiam-dan-lorem-quis-ligula-elementum-5" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Apple iPad Air 2</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9059396358" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product__price"><span className="money" doubly-currency-usd={35900}
                                                                 doubly-currency="USD">$359 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Apple iPad Air 2</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product__price"><span className="money" doubly-currency-usd={35900}
                                                                   doubly-currency="USD">$359 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Technology: GSM / CDMA / HSPA / EVDO / LTE</li>
                              <li>Dimensions: 240 x 169.5 x 6.1 mm</li>
                              <li>Weight: 437 g</li>
                              <li>Display: LED-backlit IPS LCD 9.7 inches</li>
                              <li>Resolution: 1536 x 2048</li>
                              <li>OS: iOS 8.1</li>
                              <li>Chipset: Apple A8X</li>
                              <li>CPU: Triple-core 1.5 GHz Typhoon</li>
                              <li>Internal: 16/32/64/128 GB, 2 GB RAM</li>
                              <li>Camera: 8 MP, f/2.4 - 1.2 MP, f/2.2</li>
                            </ul>
                            <div className="desc">
                              <p>
                                Another millimeter thinner, the new iPad Air 2 has a body that's even more befitting the
                                ethereal moniker the iPad bears second year in a row. Outside of that little fact
                                however, the changes may not be as easy to spot. And that's hardly a surprise.
                              </p>
                              <p>
                                Apple's laser focus on making the ultimate tablet keeps the entire tablet industry
                                moving forward. But in their camp, large strides are usually followed by baby steps and
                                not all products end up as the breakthroughs we would have liked to see. One thing's for
                                sure though, Apple certainly likes to keep us on the edge of our seats when it comes to
                                what their next gen devices would look or feel like.
                              </p>
                              <p>
                                This year, the Air 2 is hardly hitting like an airstrike, but it still does a mighty
                                fine job of keeping up the alpha male image. True, besides the slimmer profile the
                                changes are hard to see. A nice exception is the screen, where the specs may sound the
                                same on paper, but improvements are visible to the naked eye in terms of reflectivity
                                and outdoor legibility.
                              </p>
                              <p>
                                The iPad Air 2 also has a better camera, an 8 megapixel auto focus unit, for what it's
                                worth on a ten-incher, and as usual, Apple's latest and greatest chipset to make sure
                                things are running faster than ever before.
                              </p>
                              <p>
                                So that about sums up the noteworthy things the iPad Air 2 brings to the table. But
                                here's the cheat sheet of its key specs anyway and what we didn't quite like at first
                                glance.
                              </p>
                            </div>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33071079110}>16GB / Space Gray - $359.00
                                      </option>
                                      <option value={33071079174}>16GB / Silver - $359.00</option>
                                      <option value={33071079238}>16GB / Gold - $359.00</option>
                                      <option value={33071079302}>32GB / Space Gray - $389.00</option>
                                      <option value={33071079366}>32GB / Silver - $389.00</option>
                                      <option value={33071079430}>32GB / Gold - $389.00</option>
                                      <option value={33071079494}>64GB / Space Gray - $415.00</option>
                                      <option value={33071079558}>64GB / Silver - $415.00</option>
                                      <option value={33071079622}>64GB / Gold - $415.00</option>
                                      <option value={33071079686}>128GB / Space Gray - $680.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button className="btn btn-1 select-option" type="button"
                                            onClick="window.location='./product.html';" title="Select Options"><i
                                      className="fa fa-bars"/> Options
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="etiam-dan-lorem-quis-ligula-elementum-5"
                                         className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Apple iPad Air 2</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9059396358" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product__price"><span className="money" doubly-currency-usd={35900}
                                                               doubly-currency="USD">$359 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Technology: GSM / CDMA / HSPA / EVDO / LTE</li>
                          <li>Dimensions: 240 x 169.5 x 6.1 mm</li>
                          <li>Weight: 437 g</li>
                          <li>Display: LED-backlit IPS LCD 9.7 inches</li>
                          <li>Resolution: 1536 x 2048</li>
                          <li>OS: iOS 8.1</li>
                          <li>Chipset: Apple A8X</li>
                          <li>CPU: Triple-core 1.5 GHz Typhoon</li>
                          <li>Internal: 16/32/64/128 GB, 2 GB RAM</li>
                          <li>Camera: 8 MP, f/2.4 - 1.2 MP, f/2.2</li>
                        </ul>
                        <div className="desc">
                          <p>
                            Another millimeter thinner, the new iPad Air 2 has a body that's even more befitting the
                            ethereal moniker the iPad bears second year in a row. Outside of that little fact however,
                            the changes may not be as easy to spot. And that's hardly a surprise.
                          </p>
                          <p>
                            Apple's laser focus on making the ultimate tablet keeps the entire tablet industry moving
                            forward. But in their camp, large strides are usually followed by baby steps and not all
                            products end up as the breakthroughs we would have liked to see. One thing's for sure
                            though, Apple certainly likes to keep us on the edge of our seats when it comes to what
                            their next gen devices would look or feel like.
                          </p>
                          <p>
                            This year, the Air 2 is hardly hitting like an airstrike, but it still does a mighty fine
                            job of keeping up the alpha male image. True, besides the slimmer profile the changes are
                            hard to see. A nice exception is the screen, where the specs may sound the same on paper,
                            but improvements are visible to the naked eye in terms of reflectivity and outdoor
                            legibility.
                          </p>
                          <p>
                            The iPad Air 2 also has a better camera, an 8 megapixel auto focus unit, for what it's worth
                            on a ten-incher, and as usual, Apple's latest and greatest chipset to make sure things are
                            running faster than ever before.
                          </p>
                          <p>
                            So that about sums up the noteworthy things the iPad Air 2 brings to the table. But here's
                            the cheat sheet of its key specs anyway and what we didn't quite like at first glance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Apple iPad Pro"/>
                        </Link>
                        <div className="product-label">
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33071089030}>32GB / Space Gray - $1,400.00</option>
                                  <option value={33071089094}>32GB / Silver - $1,400.00</option>
                                  <option value={33071089158}>32GB / Gold - $1,400.00</option>
                                  <option value={33071089222}>128GB / Space Gray - $1,699.00</option>
                                  <option value={33071089286}>128GB / Silver - $1,699.00</option>
                                  <option value={33071089414}>128GB / Gold - $1,699.00</option>
                                  <option value={33071089478}>256GB / Space Gray - $2,000.00</option>
                                  <option value={33071089542}>256GB / Silver - $2,000.00</option>
                                  <option value={33071089606}>256GB / Gold - $2,000.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button className="btn btn-1 select-option" type="button"
                                        onClick="window.location='./product.html';" title="Select Options"><i
                                  className="fa fa-bars"/> Options
                                </button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="etiam-dan-lorem-quis-ligula-elementum-1" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Apple iPad Pro</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9059400326" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product__price"><span className="money" doubly-currency-usd={140000}
                                                                 doubly-currency="USD">$1,400 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Apple iPad Pro</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product__price"><span className="money" doubly-currency-usd={140000}
                                                                   doubly-currency="USD">$1,400 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Technology: GSM / CDMA / HSPA / EVDO / LTE</li>
                              <li>Dimensions: 305.7 x 220.6 x 6.9 mm</li>
                              <li>Weight: 713 g</li>
                              <li>Display: LED-backlit IPS LCD 12.9 inches</li>
                              <li>Resolution: 2048 x 2732</li>
                              <li>OS: iOS 9</li>
                              <li>Chipset: Apple A9X</li>
                              <li>CPU: Dual-core 2.26 GHz</li>
                              <li>Internal: 32/128/256 GB, 4 GB RAM</li>
                              <li>Camera: 8 MP - 1.2 MP</li>
                            </ul>
                            <div className="desc">
                              <p>
                                Circulating in the rumor mill for two years, with a probability of near zero for the
                                better part thereof, the iPad Pro was made official in September 2015 and is shipping
                                worldwide as we speak. Guess we already know what's been going on under the surface but
                                the bottom line is Apple lets you decide about the optional pencil and keyboard.
                              </p>
                              <p>
                                At 12.9 inches of display diagonal, the iPad Pro is Apple's largest tablet and largest
                                handheld device running iOS, considering there are a couple of smaller MacBooks.
                              </p>
                              <p>
                                But while size quite obviously matters, there's more to the giant iPad than its sheer
                                scale. A couple of firsts for Apple are also part of the package, though they naturally
                                come at an extra cost.
                              </p>
                              <p>
                                First up is the Pencil. It's an easy target of ridicule, given Steve Jobs' abhorrence of
                                the stylus, expressed at the original iPhone's launch. However, the Pencil here serves a
                                fundamentally different purpose to a stylus on a smartphone, and the jokes are mostly
                                groundless. The Pencil is an active battery powered device and houses all the tech
                                needed for precise measurement of not only different levels of pressure, but also tilt,
                                so that it's able to reproduce your drawing digitally with all its nuances as you would
                                expect from say, a specialized Wacom tablet.
                              </p>
                              <p>
                                It's the Smart Keyboard that's more entertaining, however, at least for us. Well,
                                depending on which side of the line you are in the so-called Surface tension. If you
                                need it to, the iPad Pro can be equipped with a foldable keyboard dock/kickstand, which
                                you can buy separately, just like you would the Apple Pencil.
                              </p>
                              <p>
                                Done with the trivia, the iPad Pro is a seriously capable machine with a huge display, a
                                formidable A9X chip and 4GB of RAM, all powered by an extra large battery.
                              </p>
                            </div>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33071089030}>32GB / Space Gray - $1,400.00
                                      </option>
                                      <option value={33071089094}>32GB / Silver - $1,400.00</option>
                                      <option value={33071089158}>32GB / Gold - $1,400.00</option>
                                      <option value={33071089222}>128GB / Space Gray - $1,699.00</option>
                                      <option value={33071089286}>128GB / Silver - $1,699.00</option>
                                      <option value={33071089414}>128GB / Gold - $1,699.00</option>
                                      <option value={33071089478}>256GB / Space Gray - $2,000.00</option>
                                      <option value={33071089542}>256GB / Silver - $2,000.00</option>
                                      <option value={33071089606}>256GB / Gold - $2,000.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button className="btn btn-1 select-option" type="button"
                                            onClick="window.location='./product.html';" title="Select Options"><i
                                      className="fa fa-bars"/> Options
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="etiam-dan-lorem-quis-ligula-elementum-1"
                                         className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Apple iPad Pro</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9059400326" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product__price"><span className="money" doubly-currency-usd={140000}
                                                               doubly-currency="USD">$1,400 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Technology: GSM / CDMA / HSPA / EVDO / LTE</li>
                          <li>Dimensions: 305.7 x 220.6 x 6.9 mm</li>
                          <li>Weight: 713 g</li>
                          <li>Display: LED-backlit IPS LCD 12.9 inches</li>
                          <li>Resolution: 2048 x 2732</li>
                          <li>OS: iOS 9</li>
                          <li>Chipset: Apple A9X</li>
                          <li>CPU: Dual-core 2.26 GHz</li>
                          <li>Internal: 32/128/256 GB, 4 GB RAM</li>
                          <li>Camera: 8 MP - 1.2 MP</li>
                        </ul>
                        <div className="desc">
                          <p>
                            Circulating in the rumor mill for two years, with a probability of near zero for the better
                            part thereof, the iPad Pro was made official in September 2015 and is shipping worldwide as
                            we speak. Guess we already know what's been going on under the surface but the bottom line
                            is Apple lets you decide about the optional pencil and keyboard.
                          </p>
                          <p>
                            At 12.9 inches of display diagonal, the iPad Pro is Apple's largest tablet and largest
                            handheld device running iOS, considering there are a couple of smaller MacBooks.
                          </p>
                          <p>
                            But while size quite obviously matters, there's more to the giant iPad than its sheer scale.
                            A couple of firsts for Apple are also part of the package, though they naturally come at an
                            extra cost.
                          </p>
                          <p>
                            First up is the Pencil. It's an easy target of ridicule, given Steve Jobs' abhorrence of the
                            stylus, expressed at the original iPhone's launch. However, the Pencil here serves a
                            fundamentally different purpose to a stylus on a smartphone, and the jokes are mostly
                            groundless. The Pencil is an active battery powered device and houses all the tech needed
                            for precise measurement of not only different levels of pressure, but also tilt, so that
                            it's able to reproduce your drawing digitally with all its nuances as you would expect from
                            say, a specialized Wacom tablet.
                          </p>
                          <p>
                            It's the Smart Keyboard that's more entertaining, however, at least for us. Well, depending
                            on which side of the line you are in the so-called Surface tension. If you need it to, the
                            iPad Pro can be equipped with a foldable keyboard dock/kickstand, which you can buy
                            separately, just like you would the Apple Pencil.
                          </p>
                          <p>
                            Done with the trivia, the iPad Pro is a seriously capable machine with a huge display, a
                            formidable A9X chip and 4GB of RAM, all powered by an extra large battery.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Apple Macbook Air"/>
                        </Link>
                        <div className="product-label">
                          <div className="label-element new-label">
                            <span style={{color: '#ffffff', backgroundColor: '#ffb400'}}>New</span>
                          </div>
                          <div className="label-element best-label">
                            <span style={{color: '#ffffff', backgroundColor: '#00ff27'}}>Best</span>
                          </div>
                          <div className="label-element trend-label">
                            <span style={{color: '#ffffff', backgroundColor: '#003cff'}}>Trending</span>
                          </div>
                          <div className="label-element deal-label">
                            <span style={{color: '#ffffff', backgroundColor: '#00d5d5'}}>Deal</span>
                          </div>
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33748448582}>Default Title - $1,190.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button type="submit" name="add" id="AddToCart"
                                        className="btn btn-1 add-to-cart AddToCart" title="Buy Now"><span
                                  id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span></button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="apple-macbook-air" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Apple Macbook Air</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9236050758" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <s className="product-price__price"><span className="money" doubly-currency-usd={130000}
                                                                    doubly-currency="USD">$1,300 USD</span></s>
                          <span className="product-price__price product-price__sale">
                            <span className="money" doubly-currency-usd={119000} doubly-currency="USD">$1,190 USD</span>
                            <span className="product-price__sale-label">On Sale</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Apple Macbook Air</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <s className="product-price__price"><span className="money" doubly-currency-usd={130000}
                                                                      doubly-currency="USD">$1,300 USD</span></s>
                            <span className="product-price__price product-price__sale">
                              <span className="money" doubly-currency-usd={119000}
                                    doubly-currency="USD">$1,190 USD</span>
                              <span className="product-price__sale-label">On Sale</span>
                            </span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Processor: Core i5 Broadwelle</li>
                              <li>Memory: DDR3L - 8 GB</li>
                              <li>Display: 13.3" 1440 x 900</li>
                              <li>Graphic: Intel HD Graphics 6000</li>
                              <li>Storage: SSD, 128 GB</li>
                              <li>Camera: 1.3 MP</li>
                              <li>Networking: 802.11 b/g/n</li>
                              <li>Battery: Lithium- polymer</li>
                              <li>Dimensions: 325 x 227 x 17 cmM</li>
                              <li>Weight: 1.35 kg</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33748448582}>Default Title - $1,190.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button type="submit" name="add" id="AddToCart"
                                            className="btn btn-1 AddToCart add-to-cart" title="Buy Now"><span
                                      id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span>
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="apple-macbook-air" className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Apple Macbook Air</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9236050758" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <s className="product-price__price"><span className="money" doubly-currency-usd={130000}
                                                                  doubly-currency="USD">$1,300 USD</span></s>
                        <span className="product-price__price product-price__sale">
                          <span className="money" doubly-currency-usd={119000} doubly-currency="USD">$1,190 USD</span>
                          <span className="product-price__sale-label">On Sale</span>
                        </span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Processor: Core i5 Broadwelle</li>
                          <li>Memory: DDR3L - 8 GB</li>
                          <li>Display: 13.3" 1440 x 900</li>
                          <li>Graphic: Intel HD Graphics 6000</li>
                          <li>Storage: SSD, 128 GB</li>
                          <li>Camera: 1.3 MP</li>
                          <li>Networking: 802.11 b/g/n</li>
                          <li>Battery: Lithium- polymer</li>
                          <li>Dimensions: 325 x 227 x 17 cmM</li>
                          <li>Weight: 1.35 kg</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Apple Macbook Pro 13in"/>
                        </Link>
                        <div className="product-label">
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33607041606}>Default Title - $1,499.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button type="submit" name="add" id="AddToCart"
                                        className="btn btn-1 add-to-cart AddToCart" title="Buy Now"><span
                                  id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span></button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="copy-of-copy-of-asus-rog-g752vm-1" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Apple Macbook Pro 13in</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9212386118" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product-price__price"><span className="money" doubly-currency-usd={149900}
                                                                       doubly-currency="USD">$1,499 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Apple Macbook Pro 13in</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product-price__price"><span className="money" doubly-currency-usd={149900}
                                                                         doubly-currency="USD">$1,499 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Processor: 2.0GHz Core i5</li>
                              <li>Memory: DDR4 8GB 1866MHz</li>
                              <li>Display: 13.3" Retina (2560 x 1600 pixels)</li>
                              <li>Graphic: Intel Iris Graphics 540</li>
                              <li>Storage: SSD: 256GB</li>
                              <li>Camera: HD Camera</li>
                              <li>Networking: 802.11 a/b/g/n/ac</li>
                              <li>Battery: up to 10h</li>
                              <li>Dimensions: 212,4 x 304.1 x 14,9 cmM</li>
                              <li>Weight: 1.37 kg</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33607041606}>Default Title - $1,499.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button type="submit" name="add" id="AddToCart"
                                            className="btn btn-1 AddToCart add-to-cart" title="Buy Now"><span
                                      id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span>
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="copy-of-copy-of-asus-rog-g752vm-1" className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Apple Macbook Pro 13in</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9212386118" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product-price__price"><span className="money" doubly-currency-usd={149900}
                                                                     doubly-currency="USD">$1,499 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Processor: 2.0GHz Core i5</li>
                          <li>Memory: DDR4 8GB 1866MHz</li>
                          <li>Display: 13.3" Retina (2560 x 1600 pixels)</li>
                          <li>Graphic: Intel Iris Graphics 540</li>
                          <li>Storage: SSD: 256GB</li>
                          <li>Camera: HD Camera</li>
                          <li>Networking: 802.11 a/b/g/n/ac</li>
                          <li>Battery: up to 10h</li>
                          <li>Dimensions: 212,4 x 304.1 x 14,9 cmM</li>
                          <li>Weight: 1.37 kg</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Apple Watch Edition Series 2"/>
                        </Link>
                        <div className="product-label">
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33071026118}>Edition / White / 42mm - $369.00
                                  </option>
                                  <option value={33570420358}>Edition / Sport Band / 42mm - $369.00</option>
                                  <option value={33570402502}>Edition / White / 38mm - $369.00</option>
                                  <option value={33570420550}>Edition / Sport Band / 38mm - $369.00</option>
                                  <option value={33071026182}>Sport / White / 42mm - $269.00</option>
                                  <option value={33570420422}>Sport / Sport Band / 42mm - $269.00</option>
                                  <option value={33570402566}>Sport / White / 38mm - $269.00</option>
                                  <option value={33570420614}>Sport / Sport Band / 38mm - $269.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button className="btn btn-1 select-option" type="button"
                                        onClick="window.location='./product.html';" title="Select Options"><i
                                  className="fa fa-bars"/> Options
                                </button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="example-book-4" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Apple Watch Edition Series 2</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9059379142" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product__price"><span className="money" doubly-currency-usd={26900}
                                                                 doubly-currency="USD">$269 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Apple Watch Edition Series 2</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product__price"><span className="money" doubly-currency-usd={26900}
                                                                   doubly-currency="USD">$269 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Technology: No cellular connectivity</li>
                              <li>Dimensions: 42.6 x 36.5 x 11.4 mm</li>
                              <li>Weight: 45.6 g</li>
                              <li>Display: AMOLED 1.6 inches</li>
                              <li>Resolution: 390 x 312</li>
                              <li>OS: watchOS 3.0</li>
                              <li>Chipset: Apple S2</li>
                              <li>CPU: Dual-core</li>
                              <li>Internal: No</li>
                              <li>Camera: No</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33071026118}>Edition / White / 42mm - $369.00
                                      </option>
                                      <option value={33570420358}>Edition / Sport Band / 42mm - $369.00</option>
                                      <option value={33570402502}>Edition / White / 38mm - $369.00</option>
                                      <option value={33570420550}>Edition / Sport Band / 38mm - $369.00</option>
                                      <option value={33071026182}>Sport / White / 42mm - $269.00</option>
                                      <option value={33570420422}>Sport / Sport Band / 42mm - $269.00</option>
                                      <option value={33570402566}>Sport / White / 38mm - $269.00</option>
                                      <option value={33570420614}>Sport / Sport Band / 38mm - $269.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button className="btn btn-1 select-option" type="button"
                                            onClick="window.location='./product.html';" title="Select Options"><i
                                      className="fa fa-bars"/> Options
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="example-book-4" className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Apple Watch Edition Series 2</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9059379142" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product__price"><span className="money" doubly-currency-usd={26900}
                                                               doubly-currency="USD">$269 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Technology: No cellular connectivity</li>
                          <li>Dimensions: 42.6 x 36.5 x 11.4 mm</li>
                          <li>Weight: 45.6 g</li>
                          <li>Display: AMOLED 1.6 inches</li>
                          <li>Resolution: 390 x 312</li>
                          <li>OS: watchOS 3.0</li>
                          <li>Chipset: Apple S2</li>
                          <li>CPU: Dual-core</li>
                          <li>Internal: No</li>
                          <li>Camera: No</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Asus RoG G752VM"/>
                        </Link>
                        <div className="product-label">
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33606374918}>Red - $299.00</option>
                                  <option value={33606818694}>Blue - $299.00</option>
                                  <option value={33606818822}>White - $299.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button className="btn btn-1 select-option" type="button"
                                        onClick="window.location='./product.html';" title="Select Options"><i
                                  className="fa fa-bars"/> Options
                                </button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="pellentesque-habitant-morbi-tristique-senectus"
                                     className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Asus RoG G752VM</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9059415686" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product-price__price"><span className="money" doubly-currency-usd={29900}
                                                                       doubly-currency="USD">$299 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Asus RoG G752VM</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product-price__price"><span className="money" doubly-currency-usd={29900}
                                                                         doubly-currency="USD">$299 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Processor: Intel?Pentium?N3700 Processor</li>
                              <li>Memory: DDR3 2 GB</li>
                              <li>Display: 14.0" 1920x1080</li>
                              <li>Graphic: Integrated Intel?HD Graphics</li>
                              <li>Storage: 500GB HDD 5400</li>
                              <li>Camera: VGA Web Camera</li>
                              <li>Networking: 802.11 b/g/n</li>
                              <li>Battery: 2Cells 32 Whrs</li>
                              <li>Dimensions: 339 x 235 x 21.9 cmM</li>
                              <li>Weight: 1.65 kg</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33606374918}>Red - $299.00</option>
                                      <option value={33606818694}>Blue - $299.00</option>
                                      <option value={33606818822}>White - $299.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button className="btn btn-1 select-option" type="button"
                                            onClick="window.location='./product.html';" title="Select Options"><i
                                      className="fa fa-bars"/> Options
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="pellentesque-habitant-morbi-tristique-senectus"
                                         className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Asus RoG G752VM</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9059415686" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product-price__price"><span className="money" doubly-currency-usd={29900}
                                                                     doubly-currency="USD">$299 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Processor: Intel?Pentium?N3700 Processor</li>
                          <li>Memory: DDR3 2 GB</li>
                          <li>Display: 14.0" 1920x1080</li>
                          <li>Graphic: Integrated Intel?HD Graphics</li>
                          <li>Storage: 500GB HDD 5400</li>
                          <li>Camera: VGA Web Camera</li>
                          <li>Networking: 802.11 b/g/n</li>
                          <li>Battery: 2Cells 32 Whrs</li>
                          <li>Dimensions: 339 x 235 x 21.9 cmM</li>
                          <li>Weight: 1.65 kg</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Asus ZenPad 3 8.0 Z581KL"/>
                        </Link>
                        <div className="product-label">
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33488770758}>Default Title - $280.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button type="submit" name="add" id="AddToCart"
                                        className="btn btn-1 add-to-cart AddToCart" title="Buy Now"><span
                                  id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span></button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="etiam-dan-lorem-quis-ligula-elementum-4" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Asus ZenPad 3 8.0 Z581KL</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9059397446" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product-price__price"><span className="money" doubly-currency-usd={28000}
                                                                       doubly-currency="USD">$280 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Asus ZenPad 3 8.0 Z581KL</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product-price__price"><span className="money" doubly-currency-usd={28000}
                                                                         doubly-currency="USD">$280 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Technology: GSM / HSPA / LTE</li>
                              <li>Dimensions: 205.4 x 136.4 x 7.6 mm</li>
                              <li>Weight: 320 g</li>
                              <li>Display: IPS LCD 7.9 inches</li>
                              <li>Resolution: 1536 x 2048</li>
                              <li>OS: Android OS, v6.0 (Marshmallow)</li>
                              <li>Chipset: Snapdragon 650</li>
                              <li>CPU: Hexa-core</li>
                              <li>Internal: 32 GB, 4 GB RAM</li>
                              <li>Camera: 8 MP - 2 MP</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33488770758}>Default Title - $280.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button type="submit" name="add" id="AddToCart"
                                            className="btn btn-1 AddToCart add-to-cart" title="Buy Now"><span
                                      id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span>
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="etiam-dan-lorem-quis-ligula-elementum-4"
                                         className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Asus ZenPad 3 8.0 Z581KL</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9059397446" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product-price__price"><span className="money" doubly-currency-usd={28000}
                                                                     doubly-currency="USD">$280 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Technology: GSM / HSPA / LTE</li>
                          <li>Dimensions: 205.4 x 136.4 x 7.6 mm</li>
                          <li>Weight: 320 g</li>
                          <li>Display: IPS LCD 7.9 inches</li>
                          <li>Resolution: 1536 x 2048</li>
                          <li>OS: Android OS, v6.0 (Marshmallow)</li>
                          <li>Chipset: Snapdragon 650</li>
                          <li>CPU: Hexa-core</li>
                          <li>Internal: 32 GB, 4 GB RAM</li>
                          <li>Camera: 8 MP - 2 MP</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-element grid__item medium-up--one-quarter one-quarter">
                  <div className="grid-view-item">
                    <div className="grid-normal-display">
                      <div className="grid__image product-image">
                        <Link className="grid-view-item__link" to="/product">
                          <img className="grid-view-item__image" src={ProductImage} alt="Acer Aspire E5"/>
                        </Link>
                        <div className="product-label">
                        </div>
                        <ul className="action-button">
                          <li className="wishlist">
                            <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart" title="Wishlist"/></Link>
                          </li>
                          <li className="add-to-cart-form ">
                            <form action="./product.html" method="post" encType="multipart/form-data"
                                  className="AddToCartForm form-vertical">
                              <div className="hide clearfix">
                                <select name="id">
                                  <option selected="selected" value={33747291718}>Default Title - $619.00</option>
                                </select>
                              </div>
                              <div className="effect-ajax-cart">
                                <input type="hidden" name="quantity" defaultValue={1}/>
                                <button type="submit" name="add" id="AddToCart"
                                        className="btn btn-1 add-to-cart AddToCart" title="Buy Now"><span
                                  id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span></button>
                              </div>
                            </form>
                          </li>
                          <li>
                            <div className="quickview">
                              <div className="product-ajax-cart hidden-xs hidden-sm">
                                <div data-handle="copy-of-copy-of-asus-rog-g752vm-4" className="quick_shop-div">
                                  <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                       data-effect="mfp-zoom-out">
                                    <i className="fa fa-eye" title="Quick View"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="email">
                            <a target="_blank" className="btn email-to-friend" href="#"><i className="fa fa-envelope"
                                                                                           title="Email to friend"/></a>
                          </li>
                        </ul>
                      </div>
                      <div className="grid__layout grid__information product-information">
                        <div className="h4 grid-view-item__title">
                          <Link to="/product">Acer Aspire E5</Link>
                        </div>
                        <div className="rating-star">
                          <span className="spr-badge" id="spr_badge_9235806342" data-rating={5.0}><span
                            className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                            className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                            className="spr-badge-caption">1 review</span>
                          </span>
                        </div>
                        <div className="grid-view-item__meta">
                          <span className="product-price__price"><span className="money" doubly-currency-usd={61900}
                                                                       doubly-currency="USD">$619 USD</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__layout">
                      <div className="grid-hover-display">
                        <div className="top-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__title grid__item two-thirds">
                            <Link to="/product">Acer Aspire E5</Link>
                          </div>
                          <div className="right-area grid-view-item__price grid__item one-third">
                            <span className="product-price__price"><span className="money" doubly-currency-usd={61900}
                                                                         doubly-currency="USD">$619 USD</span></span>
                          </div>
                        </div>
                        <div className="bottom-info grid__item one-whole">
                          <div className="left-area h4 grid-view-item__desc grid__item two-thirds">
                            <ul className="spec">
                              <li>Processor: Intel, Core i5 Skylake</li>
                              <li>Memory: DDR4 - 4 GB</li>
                              <li>Display: 15.6" 1920 x 1080</li>
                              <li>Graphic: NVIDIA?GeForce?940M</li>
                              <li>Storage: HDD: 500GB + SSD: 8GB</li>
                              <li>Camera: 1 MP</li>
                              <li>Networking: 802.11 b/g/n</li>
                              <li>Battery: Li-Ion 4 cell</li>
                              <li>Dimensions: 318 x 259 x 30.4 cmM</li>
                              <li>Weight: 2.23 kg</li>
                            </ul>
                          </div>
                          <div className="right-area grid-view-item__action grid__item one-third">
                            <ul className="action-button">
                              <li className="add-to-cart-form grid__item one-whole">
                                <form action="./product.html" method="post" encType="multipart/form-data"
                                      className="AddToCartForm form-vertical">
                                  <div className="hide clearfix">
                                    <select name="id">
                                      <option selected="selected" value={33747291718}>Default Title - $619.00</option>
                                    </select>
                                  </div>
                                  <div className="effect-ajax-cart">
                                    <input type="hidden" name="quantity" defaultValue={1}/>
                                    <button type="submit" name="add" id="AddToCart"
                                            className="btn btn-1 AddToCart add-to-cart" title="Buy Now"><span
                                      id="AddToCartText"><i className="fa fa-shopping-cart"/> Add to Cart</span>
                                    </button>
                                  </div>
                                </form>
                              </li>
                              <li className="quick-shop grid__item one-whole">
                                <div className="quickview">
                                  <div className="product-ajax-cart hidden-xs hidden-sm">
                                    <div data-handle="copy-of-copy-of-asus-rog-g752vm-4" className="quick_shop-div">
                                      <div className="btn quick_shop" data-mfp-src="#quick-shop-modal"
                                           data-effect="mfp-zoom-out">
                                        <i className="fa fa-eye" title="Quick View"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="wishlist grid__item one-whole">
                                <Link className="wish-list btn" to="/wishlist"><i className="fa fa-heart"
                                                                                  title="Wishlist"/></Link>
                              </li>
                              <li className="email grid__item one-whole">
                                <a target="_blank" className="btn email-to-friend" href="#"><i
                                  className="fa fa-envelope" title="Email to friend"/></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-layout">
                      <div className="h4 grid-view-item__title">
                        <Link to="/product">Acer Aspire E5</Link>
                      </div>
                      <div className="rating-star">
                        <span className="spr-badge" id="spr_badge_9235806342" data-rating={5.0}><span
                          className="spr-starrating spr-badge-starrating"><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/><i
                          className="spr-icon spr-icon-star"/><i className="spr-icon spr-icon-star"/></span><span
                          className="spr-badge-caption">1 review</span>
                        </span>
                      </div>
                      <div className="grid-view-item__meta">
                        <span className="product-price__price"><span className="money" doubly-currency-usd={61900}
                                                                     doubly-currency="USD">$619 USD</span></span>
                      </div>
                      <div className="grid-description">
                        <ul className="spec">
                          <li>Processor: Intel, Core i5 Skylake</li>
                          <li>Memory: DDR4 - 4 GB</li>
                          <li>Display: 15.6" 1920 x 1080</li>
                          <li>Graphic: NVIDIA?GeForce?940M</li>
                          <li>Storage: HDD: 500GB + SSD: 8GB</li>
                          <li>Camera: 1 MP</li>
                          <li>Networking: 802.11 b/g/n</li>
                          <li>Battery: Li-Ion 4 cell</li>
                          <li>Dimensions: 318 x 259 x 30.4 cmM</li>
                          <li>Weight: 2.23 kg</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withRouter(SearchResult);