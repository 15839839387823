import React from 'react';
import '../assets/css/main.css'
import {Link} from 'react-router-dom';
import NewLogo from '../assets/logos/Argo-logo-4.png'
import Drawer from '@material-ui/core/Drawer';
import MenuNavigation from './MenuNavigation'


const Menu = () => {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <div data-section-id="header" data-section-type="header-section header-style-7" className="header-section header-style-7">
      <div className="header-wrapper">
        <header className="site-header" role="header">
          <div className="wrapper main-header-wrapper">
            <div className="grid--full grid--table">
              <div className="grid__item large--hide medium-down--one-quarter small--one-sixth">
                <div className="site-nav--mobile">
                  <React.Fragment key='right'>
                    <Drawer anchor='right' open={state['right']} onClose={toggleDrawer('right', false)}>
                      <MenuNavigation onClose={() => toggleDrawer('right', false)}/>
                    </Drawer>
                    <button type="button" className="icon-fallback-text site-nav__link js-drawer-open-left" onClick={toggleDrawer('right', true)}>
                      <span className="icon icon-hamburger" aria-hidden="true" />
                      <span className="fallback-text">Site navigation</span>
                    </button>
                  </React.Fragment>
                </div>
              </div>
              <div className="grid__item large--hide medium-down--two-quarter small--three-fifths" style={{backgroudColor: '#fff'}}>
                <Link to="/" itemProp="url" className="site-header__logo-image">
                  <img src={NewLogo} alt="" />
                </Link>
              </div>
              <div className="grid__item large--show medium-down--hide smal--hide">
                <div className="grid__item large--one-quarter logo-wrapper">
                  <Link to="/" itemProp="url" className="site-header__logo-image">
                    <img src={NewLogo} alt="" />
                  </Link>
                </div>
                <div className="grid__item large--three-quarters nav-links">
                  <div className="nav-menu">
                    <div className="navigation-bar menu-bar navigation2">
                      <div className="wrapper">
                        <div className="grid--full grid--table">
                          <div className="grid__item large--one-whole small--hide navigation-wrapper">
                            <ul className="site-nav site-nav--compress site-nav--init" id="AccessibleNav">
                              <li className="site-nav__item site-nav--compress__menu">
                                <React.Fragment key='right'>
                                  <Drawer anchor='right' open={state['right']} onClose={toggleDrawer('right', false)}>
                                    <MenuNavigation onClose={toggleDrawer('right', false)} />
                                  </Drawer>
                                  <button type="button" className="icon-fallback-text site-nav__link site-nav__link--icon js-drawer-open-left" aria-controls="NavDrawer" aria-expanded="false" onClick={toggleDrawer('right', true)}>
                                    <span className="icon icon-hamburger" aria-hidden="true" />
                                    <span className="fallback-text">Site navigation</span>
                                  </button>
                                </React.Fragment>
                              </li>
                              <li className="site-nav__item site-nav__expanded-item site-nav--has-dropdown site-nav--active dropdown navigation desknav" aria-haspopup="true">
                                <Link to="/" className="site-nav__link">
                                  <span>Главная</span>
                                </Link>
                              </li>
                              <li className="site-nav__item site-nav__expanded-item site-nav--has-dropdown site-nav--active dropdown navigation desknav" aria-haspopup="true">
                                <Link to="/productlist" className="site-nav__link">
                                  <span>Продукты</span>
                                </Link>
                              </li>
                              <li className="site-nav__item site-nav__expanded-item site-nav--has-dropdown site-nav--active dropdown navigation desknav" aria-haspopup="true">
                                <Link to="/aboutus" className="site-nav__link">
                                  <span>О Компании</span>
                                </Link>
                              </li>
                              <li className="site-nav__item site-nav__expanded-item site-nav--has-dropdown dropdown navigation desknav" aria-haspopup="true">
                                <Link to="/blogs" className="site-nav__link">
                                  <span>Блог</span>
                                </Link>
                              </li>
                              <li className="site-nav__item site-nav__expanded-item ">
                                <Link to="/pricelist" className="site-nav__link">
                                  <span>Прайс-лист</span>
                                </Link>
                              </li>
                              <li className="site-nav__item site-nav__expanded-item ">
                                <Link to="/sales" className="site-nav__link">
                                  <span>Акции</span>
                                </Link>
                              </li>
                              <li className="site-nav__item site-nav__expanded-item ">
                                <Link to="/contact" className="site-nav__link">
                                  <span>Контакты</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Menu;