import React from 'react';
import { withRouter, Link } from "react-router-dom";
import ProductImage from '../assets/images/product1_320x320.jpg'

function WishList() {
  return (
    <main className="main-content">
      <div id="html-section-product-template" className="html-section">
        <header className="section-header wrapper-breadcrumb">
          <div className="wrapper">
            <h3>Wishlist</h3>
            <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
              <Link to="/" title="Back to the frontpage">Home</Link>
              <span aria-hidden="true"><i className="fa fa-angle-right" aria-hidden="true"/></span>
              <span>Wishlist</span>
            </nav>
          </div>
        </header>
        <div className="wrapper">
          <div id="col-main" className="clearfix">
            <div className="page">
              <div className="table-cart">
                <div className="non-wl hide">
                  <p>
                    Your wish list is empty
                  </p>
                  Continue browsing <Link to="/productlist">here</Link>.
                </div>
                <div className="wrap-table">
                  <table className="wishlist-items full table--responsive">
                    <thead className="cart__row">
                    <tr className="top-labels">
                      <th className="text-center">
                        Items
                      </th>
                      <th>
                        Price
                      </th>
                      <th>
                        Remove
                      </th>
                      <th>
                        Add to Cart
                      </th>
                    </tr>
                    </thead>
                    <tbody className="checkhtml">
                    <tr className="cart__row">
                      <td className="product-name" data-label="Items">
                        <div className="img_item">
                          <Link className="image text-left" to="/product"><img src={ProductImage}
                                                                               alt="Motorola Moto 360 (2nd gen)"/></Link>
                        </div>
                        <div className="link">
                          <p className="product-title">
                            <Link className="title text-left" to="/product">Motorola Moto 360 (2nd gen)</Link>
                          </p>
                        </div>
                      </td>
                      <td className="title-1" data-label="Price">
                        <span className="h3"><span className="money" doubly-currency-usd={34999} doubly-currency="USD">$350 USD</span></span>
                      </td>
                      <td className="action" data-label="Remove">
                        <form method="post" action="#" id="contact_form" acceptCharset="UTF-8" className="contact-form">
                          <input type="hidden" name="form_type" defaultValue="customer"/><input type="hidden"
                                                                                                name="utf8"
                                                                                                defaultValue="?"/>
                          <input type="hidden" name="contact[email]" defaultValue="estt@gmail.com"/>
                          <input type="hidden" name="contact[tags]" defaultValue="x9059380358"/>
                          <button type="submit"><i className="fa fa-times-circle"/></button>
                        </form>
                      </td>
                      <td data-label="Add to Cart">
                        <form action="/cart/add" method="post">
                          <input type="hidden" name="quantity" defaultValue={1}/>
                          <button className="btn btn-1 select-option" type="button"
                                  onClick="window.location='/product';"><i className="fa fa-bars"/> Options
                          </button>
                        </form>
                      </td>
                    </tr>
                    <tr className="cart__row">
                      <td className="product-name" data-label="Items">
                        <div className="img_item">
                          <Link className="image text-left" to="/product"><img src={ProductImage}
                                                                               alt="Samsung Galaxy Tab S 8.4 LTE"/></Link>
                        </div>
                        <div className="link">
                          <p className="product-title">
                            <Link className="title text-left" to="/product">Samsung Galaxy Tab S 8.4 LTE</Link>
                          </p>
                        </div>
                      </td>
                      <td className="title-1" data-label="Price">
                        <span className="h3"><span className="money" doubly-currency-usd={55898} doubly-currency="USD">$559 USD</span></span>
                      </td>
                      <td className="action" data-label="Remove">
                        <form method="post" action="#" id="contact_form" acceptCharset="UTF-8" className="contact-form">
                          <input type="hidden" name="form_type" defaultValue="customer"/><input type="hidden"
                                                                                                name="utf8"
                                                                                                defaultValue="?"/>
                          <input type="hidden" name="contact[email]" defaultValue="estt@gmail.com"/>
                          <input type="hidden" name="contact[tags]" defaultValue="x9059395590"/>
                          <button type="submit"><i className="fa fa-times-circle"/></button>
                        </form>
                      </td>
                      <td data-label="Add to Cart">
                        <form action="/cart/add" method="post">
                          <input type="hidden" name="quantity" defaultValue={1}/>
                          <button className="btn btn-1 select-option" type="button" onClick="window.location='/product">
                            <i className="fa fa-bars"/> Options
                          </button>
                        </form>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withRouter(WishList);