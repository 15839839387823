import React from 'react';
import {Link} from "react-router-dom";


const ProductListItem = ({slug, image, name, international_name, release_form, pharmacotherapeutic_group}) => {
  return (
    <div className="grid-view-item">
      <div className="grid__image product-image">
        <img className="grid-view-item__image" src={image} alt={name} />
      </div>
      <div className="grid__layout grid__information product-information">
        <div className="h3 grid-view-item__title product-list-item-title">{name}</div>
        <div className="grid-view-item__meta">
          <ul className="product-spec">
            <li>
              <div className="tooltip">
                <b>МНН: </b>
                {international_name && international_name.substring(0, 60).concat(international_name.length > 60 ? "..." : "")}
                {international_name && international_name.length > 60 ? <span className="tooltiptext">{international_name}</span> : null}
              </div>
            </li>
            <li>
              <div className="tooltip">
                <b>Фармакотерапевтическая группа: </b>
                {pharmacotherapeutic_group && pharmacotherapeutic_group.substring(0, 60).concat(pharmacotherapeutic_group.length > 60 ? "..." : "")}
                {pharmacotherapeutic_group && pharmacotherapeutic_group.length > 60 ? <span className="tooltiptext">{pharmacotherapeutic_group}</span> : null}
              </div>
            </li>
          </ul>
        </div>
        <span className="product-catalog-view-button loadmore btn">
          <Link to={"/product/" + slug}>Посмотреть</Link>
        </span>
      </div>
    </div>
  )
};

export default ProductListItem;