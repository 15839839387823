import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';


import PriceListItem from './components/PriceListItem'
import PriceListSearch from './components/PriceListSearch'
import Loader from '../common/components/Loader'
import {getPriceList} from './services/priceListServices'
import {get} from "../common/services/NetService";

import '../assets/css/basicComponents.css';

class PriceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      hasMoreItems: true,
      nextHref: null,
      filterName: ''
    };
    this.resetFilter = this.resetFilter.bind(this);
  }

  resetFilter(name) {
    this.setState({
      products: [],
      hasMoreItems: true,
      nextHref: null,
      filterName: name
    });
  }

  async loadItems(page) {
    let res = null;
    if (!this.state.nextHref && this.state.hasMoreItems) {
      res = await getPriceList(this.state.filterName);
    } else if (this.state.nextHref) {
      res = await get(`${this.state.nextHref}`);
    }
    if (res) {
      let tracks = this.state.products;
      res.data.results.forEach((product) => {
        tracks.push(product);
      });

      if (res.data.next) {
        this.setState({
          products: tracks,
          nextHref: res.data.next
        });
      } else {
        this.setState({
          hasMoreItems: false
        });
      }
    }
  }

  render() {
    let items = [];
    this.state.products.map((product) => {
      items.push(
        <div className="grid-element grid__item">
          <PriceListItem
            name={product.name}
            international_name={product.international_name}
            partner={product.partner}
            expire_date={product.expire_date}
            wholesale={product.wholesale}/>
        </div>
      );
    });

    return (
      <main className="main-content">
        <div id="html-section-collection-template-full" className="html-section">
          <div id="CollectionSection" className="collection-full" data-section-id="collection-template-full"
               data-section-type="collection-template" data-grid-type={4}>
            <section id="collection_content">
              <div>
              </div>
              <div className="wrapper">
                <div className="grid--rev" id="collection">
                  <div className="grid__item large--one-whole">
                    <PriceListSearch onFilterChange={this.resetFilter}/>
                    <div className="grid-uniform">
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadItems.bind(this)}
                        hasMore={this.state.hasMoreItems}
                        loader={Loader()}>
                        {items}
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(PriceList);