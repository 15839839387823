import React from 'react';
import { withRouter } from "react-router-dom";
import Slideshow from './components/Slideshow'
import Partners from './components/Partners'
import Products from './components/Products'
import Blogs from './components/Blogs'

function Main() {
  return (
    <>
      <Slideshow/>
      <Partners/>
      <Products/>
      <Blogs/>
    </>
  );
}

export default withRouter(Main);