import React from 'react'
import {Link} from 'react-router-dom'

const MenuNavigation = ({onClose}) => {
  return (
    <div className="mobile_menu_container">
      <a href="#" className="close" onClick={onClose}/>
      <ul className="mobile-nav">
        <li className="mobile-nav__item">
          <Link to="/" className="mobile-nav__link" onClick={onClose}>Главная</Link>
        </li>
        <li className="mobile-nav__item">
          <Link to="/productlist" className="mobile-nav__link" onClick={onClose}>Продукты</Link>
        </li>
        <li className="mobile-nav__item">
          <Link to="./aboutus" className="mobile-nav__link" onClick={onClose}>О Компании</Link>
        </li>
        <li className="mobile-nav__item">
          <Link to="./blogs" className="mobile-nav__link" onClick={onClose}>Блог</Link>
        </li>
        <li className="mobile-nav__item">
          <Link to="./pricelist" className="mobile-nav__link" onClick={onClose}>Прайс-лист</Link>
        </li>
        <li className="mobile-nav__item">
          <Link to="./sales" className="mobile-nav__link" onClick={onClose}>Акции</Link>
        </li>
        <li className="mobile-nav__item">
          <Link to="/contact" className="mobile-nav__link" onClick={onClose}>Контакты</Link>
        </li>
      </ul>
    </div>
  );
}

export default MenuNavigation
