import React from 'react';
import { withRouter, Link } from "react-router-dom";
import {sendEmail} from "./services/sendEmailService";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ButtonLoader from '../assets/images/button-loader.gif'


const Contact = () => {
  const [contactInfo, setContactInfo] = React.useState({
    email: '',
    name: '',
    subject: '',
    content: '',
    phone: '',
  });
  const [loader, setLoader] = React.useState(false);
  const [contactInfoValidattion, setContactInfoValidattion] = React.useState({});

  let sendContactEmail = async () => {
    setContactInfoValidattion({});
    setLoader(true);
    sendEmail(contactInfo)
      .then((res) => {
        setLoader(false);
        if (res['statusCode'] === 200) {
          NotificationManager.success('Письмо успешно отправлено.', 'Письмо отправлено');
        }
      })
      .catch((err) => {
        setLoader(false);
        let errorFields = JSON.parse(err['message']);
        setContactInfoValidattion(errorFields);
      })
  };

  return (
    <main className="main-content">
      <div className="html-section">
        <div className="wrapper">
          <div className="grid">
            <div className="grid__item contact-wrapper">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3049.2043094978812!2d44.428665151304926!3d40.16000457929463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abe6e0c50cf97%3A0x923d3b216c61d3c5!2sArgo-Pharm%20Ltd!5e0!3m2!1sen!2s!4v1617098251521!5m2!1sen!2s"
                width="100%" height={450} frameBorder={0} style={{border: 0, marginBottom: '40px', marginTop: '40px'}} allowFullScreen>
              </iframe>
              <div className="grid__item large--one-half" style={{paddingLeft: 0}}>
                <ul>
                  <li className="address">
                    <div className="address-container">
                      <i className="fa fa-street-view" />Адрес:
                      <div className="address-list">
                        <div>0027, Армения, Ереван, ул. Исакова 50/3</div>
                        <div>0064, Армения, Ереван, ул. Раффи 111</div>
                      </div>
                    </div>
                  </li>
                  <li className="open"><i className="fa fa-check" />Время работы
                    <ul>
                      <li>Пн - Пт : 09.30 - 18.00</li>
                    </ul>
                  </li>
                  <li className="email">
                    <div className="address-container">
                      <i className="fa fa-envelope-o" />Эл.почта:
                      <div className="address-list">
                        <div>argo@argopharm.am</div>
                        <div>info@argopharm.am</div>
                      </div>
                    </div>
                  </li>
                  <li className="email">
                    <div className="address-container">
                      <i className="fa fa-phone" />Тел:
                      <div className="address-list">
                        <div>+374 (0)11 744080</div>
                        <div>+374 (0)12 744080</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="form-vertical grid__item large--one-half">
                {
                  contactInfoValidattion['email'] ?
                  contactInfoValidattion['email'] === 'blank' ?
                  <div className="contact-form-required-labels">Эл. адрес - обязательное поле.</div> :
                    <div className="contact-form-required-labels">Эл. адрес - введите корректный адрес.</div>
                    : null
                }
                <div className="grid grid--small">
                  <div className="grid__item large--one-half">
                    <input type="email" className="email-field input-full"
                           name="contact[email]" placeholder="Эл. адрес"
                           autoCorrect="off" autoCapitalize="off"
                           onChange={(e) => setContactInfo({...contactInfo, email: e.target.value})}/>
                  </div>
                  <div className="grid__item large--one-half">
                    <input type="tel" className="email-field input-full"
                           name="contact[phone]" placeholder="Телефон"
                           onChange={(e) => setContactInfo({...contactInfo, phone: e.target.value})}/>
                  </div>
                </div>
                {
                  contactInfoValidattion['name'] ?
                    <div className="contact-form-required-labels">Имя - обязательное поле.</div> : null
                }
                <input type="text" className="email-field input-full"
                       name="contact[name]" placeholder="Имя"
                       autoCapitalize="words"
                       onChange={(e) => setContactInfo({...contactInfo, name: e.target.value})}/>
                {
                  contactInfoValidattion['subject'] ?
                    <div className="contact-form-required-labels">Тема - обязательное поле.</div> : null
                }
                <input type="text" className="email-field input-full"
                       name="contact[theme]" placeholder="Тема"
                       autoCapitalize="words"
                       onChange={(e) => setContactInfo({...contactInfo, subject: e.target.value})}/>
                {
                  contactInfoValidattion['content'] ?
                    <div className="contact-form-required-labels">Сообщение - обязательное поле.</div> : null
                }
                <textarea rows={5} className="email-field input-full"
                          name="contact[body]"
                          placeholder="Сообщение" defaultValue={""}
                          onChange={(e) => setContactInfo({...contactInfo, content: e.target.value})}/>
                <span className="email-send product-catalog-view-button loadmore btn" onClick={sendContactEmail}>
                  <div>
                    {loader ? null : <div>Отправить электронное письмо</div>}
                    {loader ? <img src={ButtonLoader} style={{width: '20px', height: '20px'}}/> : null}
                  </div>
                </span>
                <NotificationContainer/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withRouter(Contact);