import React from 'react';
import "../../assets/css/pricelist.css"


const PricetListHeader = () => {
  return (
    <div className="price_list_header price_list_view_item">
      <div className="price_list_container">
        <div className="price_list_header_cell price_list_container_cell price_list_cell1">
          Наименование товаров
        </div>
        <div className="price_list_header_cell price_list_container_cell price_list_cell2">
          Производитель
        </div>
        <div className="price_list_header_cell price_list_container_cell price_list_cell2">
          МНН
        </div>
        <div className="price_list_header_cell price_list_container_cell price_list_cell3">
          Срок годности
        </div>
        <div className="price_list_container_cell price_list_cell4">
          Цена AMD
        </div>
      </div>
    </div>
  )
};

export default PricetListHeader;