import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductListItem from '../../ProductList/components/ProductListItem'


const RelatedProducts = ({products}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };
  return (
      <Carousel
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="realted-element"
        className="related-products-items grid-uniform related-slider owl-carousel owl-theme"
      >
        {products.map((product) =>
          <div className="grid__item">
            <ProductListItem
              image={product.product_image_url}
              slug={product.slug}
              name={product.name}
              international_name={product.international_name}
              release_form={product.release_form}
              pharmacotherapeutic_group={product.pharmacotherapeutic_group}/>
          </div>
        )}
      </Carousel>
  )
};

export default RelatedProducts

