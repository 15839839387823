import React, {useEffect, useState} from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BlogListItem from '../../Blog/Components/BlogListItem'
import {getBlogsList} from "../../Blog/services/blogListService";

const Blogs = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    let blogsResult = await getBlogsList();
    if (blogsResult) {
      setBlogs(blogsResult.data.results)
    }
  };

  return (
    <div className="html-section index-section blogs-slider">
      <div className="wrapper">
        <div className="home-blog-wrapper" style={{paddingTop: '50px', paddingBottom: '50px'}}>
          <header className="section-header style_3">
            <h2 className="section-title">Мероприятия</h2>
          </header>
          <div className="grid grid--uniform grid--blog blog-slider">
            <Carousel
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              keyBoardControl={true}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              itemClass="carousel-item-padding-40-px"
            >
              {
                blogs.map(blog => {
                  return <BlogListItem slug={blog.slug}
                                       image={blog.image_url}
                                       title={blog.title}
                                       date={blog.date}
                                       description={blog.description.replace(/<[^>]*>/g, '')}
                                       key={blog.title}/>
                })
              }
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blogs