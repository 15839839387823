import React, {useEffect, useState} from 'react';
import {Slide} from 'react-slideshow-image';
import {Link} from 'react-router-dom'
import {getSlidesList} from "../services/mainServices";
import '../../assets/css/slideshow.css'

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
};

const SlideshowItem = (props) => {
  let leftPart = (
    <div className="feature-row__item">
      <img src={props.image_url} alt="" className="feature-row__image"/>
    </div>
  );
  let rightPart = (
    <div className={`feature-row__item feature-row__text ${props.imagePositionLeft ? "feature-row__text--left" : "feature-row__text--right"}`}>
      <h2 className="h3">{props.name}</h2>
      <div className="rte-setting featured-row__subtext">
        <div className="slide-description" dangerouslySetInnerHTML={{ __html: props.description }} />
      </div>
    </div>
  );
  return (
    <div className={`each-fade fade-${props.index % 3}`}>
      <div className="page-width feature-row">
        <div className="feature-row">
          {props.imagePositionLeft ? leftPart : rightPart}
          {props.imagePositionLeft ? rightPart : leftPart}
        </div>
      </div>
    </div>
  )
};

const Slideshow = () => {
  const [slides, setSlides] = useState('');

  useEffect(() => {
    getSlides();
  }, []);

  const getSlides = async () => {
    let slidesResult = await getSlidesList();
    if (slidesResult) {
      setSlides(slidesResult.data)
    }
  };

  return (
    <div className="slide-container">
      <Slide {...fadeProperties}>
        {[...slides].map((slide, id) => {
          return <SlideshowItem
            imagePositionLeft={!(id % 2)}
            index={id}
            name={slide.title}
            description={slide.description}
            image_url = {slide.image_url}
            key={id}
          />
        })}
      </Slide>
    </div>
  )
};

export default Slideshow;