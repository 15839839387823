import { get } from "../../common/services/NetService";
import config from "../../common/services/configurationService";
import {SLIDES_URL, PARTNER_URL, PRODUCT_URL} from "../../common/services/url";

const LIST = 'list';
const FEATURED_PRODUCTS_LIST = 'featured_product_list';

export const getSlidesList = async () => {
  return await get(`${config.URL}/${SLIDES_URL}/${LIST}`);
};

export const getPartnersList = async () => {
  return await get(`${config.URL}/${PARTNER_URL}/${LIST}`);
};

export const getFeaturedProductsList = async () => {
  return await get(`${config.URL}/${PRODUCT_URL}/${FEATURED_PRODUCTS_LIST}`);
}