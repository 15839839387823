import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import {getPartnersList} from "../../Main/services/mainServices";
import SearchLogo from '../../assets/logos/search-logo.png'


const ProductListSearch = ({onFilterChange}) => {
  const [partnersOptions, setPartnersOptions] = useState([]);
  const [name, setName] = useState("");
  const [filterOptions, setFilterOptios] = useState(({}));
  useEffect(() => {getPartners()}, []);

  const getPartners = async () => {
    let partnersResult = await getPartnersList();
    if (partnersResult) {
      setPartnersOptions(partnersResult.data.map(partner => ({label: partner.name, value: partner.id})))
    }
  };

  const handlePartnerChange = e => {
    filterOptions.pharma_company_id = e.value;
    onFilterChange(filterOptions);
  };

  const handleNameChange = e => {
    if (name) {
      filterOptions.name = name;
    } else if (filterOptions.name) {
      delete filterOptions.name;
    }
    onFilterChange(filterOptions);
  };

  const handleRecipeChange = e => {
    filterOptions.dispensing_no_recipe = e.target.value === "with_recipe";
    onFilterChange(filterOptions);
  };

  return (
    <div className="collection-toolbar">
      <div className = "collection-actions">
        <div className="collection-label">
          <div>Поиск лекарственных препаратов</div>
        </div>
        <div className="collection-search">
          <div className="collection-filter">
            <Select options={partnersOptions} className='collection-filtering'
                    classNamePrefix="category-select" placeholder='Все партнеры' isSearchable={true}
                    onChange={handlePartnerChange}
            />
          </div>
          <div className="collection-filter">
            <input placeholder='Введите название препарата' className='collection-name-search'
                   onChange={e => setName(e.target.value)}
                   onKeyPress={(e) => e.key === 'Enter' ? handleNameChange(e) : null}/>
          </div>
          <img src={SearchLogo} alt="" className='collection-search-icon' onClick={handleNameChange}/>
        </div>
      </div>
      <div className="container">
        <label>
          <input type="radio" value="no_recipe" name="prescription" onClick={handleRecipeChange}/>
          <span>Безрецептурные препараты</span>
        </label>
        <label>
          <input type="radio" value="with_recipe" name="prescription" onClick={handleRecipeChange}/>
          <span>Рецептурные препараты</span>
        </label>
      </div>
    </div>
  )
};

export default ProductListSearch;