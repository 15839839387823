import React, {useEffect, useState} from 'react';
import { withRouter } from "react-router-dom";
import {getSale} from './services/saleService'
import "react-image-gallery/styles/css/image-gallery.css"


const Sale = (props) => {
  const [sale, setSale] = useState(null);
  const {
    match: {
      params: {slug},
    },
  } = props;

  useEffect(() => {
    async function getSaleBySlug() {
      const sale = await getSale(slug);
      setSale(sale.data);
    }
    getSaleBySlug();
  }, []);

  if (sale) {
    return (
      <main className="main-content">
        <div id="html-section-blog-template" className="html-section">
          <div className="wrapper">
            <div className="blog-main-title">
              <h2>{sale.title}</h2>
              <p className="blog-main-date">
                <span className="date"><time dateTime={sale.start_date}>{sale.start_date}</time></span>
                <span className="date">&nbsp;-&nbsp;</span>
                <span className="date"><time dateTime={sale.end_date}>{sale.end_date}</time></span>
              </p>
            </div>
            <img src={sale.image_url} alt="" style={{width: "100%"}}/>
            <div className="blog-main-description" dangerouslySetInnerHTML={{ __html: sale.description }} />
          </div>
        </div>
      </main>
    );
  } else {
    return null;
  }
};

export default withRouter(Sale);