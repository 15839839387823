import React from 'react';
import { withRouter, Link } from "react-router-dom";

const Register = () => {
  return (
    <main className="main-content">
      <div id="html-section-product-template" className="html-section">
        <header className="section-header wrapper-breadcrumb">
          <div className="wrapper">
            <h3>Account Register</h3>
            <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
              <Link to="/" title="Back to the frontpage">Home</Link>
              <span aria-hidden="true"><i className="fa fa-angle-right" aria-hidden="true" /></span>
              <span>Account Register</span>
            </nav>
          </div>
        </header>
        <div className="grid">
          <div className="grid__item large--one-third push--large--one-third text-center">
            <div className="form-vertical">
              <form method="post" action="#" id="create_customer" acceptCharset="UTF-8">
                <input type="hidden" name="form_type" defaultValue="create_customer" /><input type="hidden" name="utf8" defaultValue="?" />
                <label htmlFor="FirstName" className="hidden-label">First Name</label>
                <input type="text" name="customer[first_name]" id="FirstName" className="input-full" placeholder="First Name" autoCapitalize="words" autofocus />
                <label htmlFor="LastName" className="hidden-label">Last Name</label>
                <input type="text" name="customer[last_name]" id="LastName" className="input-full" placeholder="Last Name" autoCapitalize="words" />
                <label htmlFor="Email" className="hidden-label">Email</label>
                <input type="email" name="customer[email]" id="Email" className="input-full" placeholder="Email" autoCorrect="off" autoCapitalize="off" />
                <label htmlFor="CreatePassword" className="hidden-label">Password</label>
                <input type="password" name="customer[password]" id="CreatePassword" className="input-full" placeholder="Password" />
                <p>
                  <input type="submit" defaultValue="Create" className="btn btn--full" />
                </p>
                <Link to="/">Return to Store</Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default withRouter(Register);