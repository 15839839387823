import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';


import Loader from '../common/components/Loader'
import ProductListItem from './components/ProductListItem'
import ProductListSearch from './components/ProductListSearch'
import {filterProductsList} from './services/productListServices'
import {post} from "../common/services/NetService";

import '../assets/css/basicComponents.css';

class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      hasMoreItems: true,
      nextHref: null,
      filterOptions: {}
    };
    this.resetFilter = this.resetFilter.bind(this);
  }

  resetFilter(options) {
    this.setState({
      products: [],
      hasMoreItems: true,
      nextHref: null,
      filterOptions: options
    });
  }

  async loadItems(page) {
    let res = null;
    if (!this.state.nextHref && this.state.hasMoreItems) {
      res = await filterProductsList(this.state.filterOptions);
    } else if (this.state.nextHref) {
      res = await post(`${this.state.nextHref}`, this.state.filterOptions);
    }
    if (res) {
      let tracks = this.state.products;
      res.data.results.forEach((product) => {
        tracks.push(product);
      });

      if (res.data.next) {
        this.setState({
          products: tracks,
          nextHref: res.data.next
        });
      } else {
        this.setState({
          hasMoreItems: false
        });
      }
    }
  }

  render() {
    let items = [];
    this.state.products.map((product) => {
      items.push(
        <div className="grid-element grid__item medium-up--one-quarter one-quarter">
          <ProductListItem
            image={product.product_image_url}
            slug={product.slug}
            name={product.name}
            international_name={product.international_name}
            release_form={product.release_form}
            pharmacotherapeutic_group={product.pharmacotherapeutic_group}/>
        </div>
      );
    });

    return (
      <main className="main-content">
        <div id="html-section-collection-template-full" className="html-section">
          <div id="CollectionSection" className="collection-full" data-section-id="collection-template-full"
               data-section-type="collection-template" data-grid-type={4}>
            <section id="collection_content">
              <div>
              </div>
              <div className="wrapper">
                <div className="grid--rev" id="collection">
                  <div className="grid__item large--one-whole">
                    <ProductListSearch onFilterChange={this.resetFilter}/>
                    <div className="section-header style_3">
                      <h2>Препараты</h2>
                    </div>
                    <div className="grid-uniform grid-uniform-category ">
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadItems.bind(this)}
                        hasMore={this.state.hasMoreItems}
                        loader={Loader()}>
                        {items}
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(ProductList);