import React from 'react';
import './assets/css/timber.scss.css';
import './assets/css/theme.scss.css';
import './assets/css/main.css';
import Menu from './Menu'
import Footer from "./Footer";
import Main from './Main'
import Login from './Login'
import AboutUs from './AboutUs'
import ProductList from './ProductList'
import PriceList from './PriceList'
import Product from './Product'
import Account from './Account'
import Blog from './Blog'
import SalesList from './SalesList'
import Sale from './Sale'
import Article from './Article'
import Register from './Register'
import Contact from './Contact'
import WishList from './Wishlist'
import SearchResult from './SearchResult'
import DrugSafety from "./DrugSafety";
import { BrowserRouter, Route } from 'react-router-dom'


function App() {
  return (
    <BrowserRouter onUpdate={() => document.getElementById('main-content').focus()}>
      <main className="main-content">
        <div className="content">
          <div className="index-sections">
            <div id="html-section-header" className="html-section">
              <Menu/>
            </div>
            <div>
              <Route exact path="/" component={Main} />
              <Route path="/login" component={Login} />
              <Route path="/aboutus" component={AboutUs} />
              <Route path="/product/:slug" component={Product} />
              <Route path="/account" component={Account} />
              <Route path="/productlist" component={ProductList} />
              <Route path="/pricelist" component={PriceList}/>
              <Route path="/blogs" component={Blog} />
              <Route path="/sales" component={SalesList} />
              <Route path="/sale/:slug" component={Sale} />
              <Route path="/blog/:slug" component={Article} />
              <Route path="/register" component={Register} />
              <Route path="/contact" component={Contact} />
              <Route path="/wishlist" component={WishList} />
              <Route path="/searchresult" component={SearchResult} />
              <Route path="/drugsafety" component={DrugSafety} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
