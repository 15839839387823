import { get, post } from "../../common/services/NetService";
import config from "../../common/services/configurationService";
import { PRODUCT_URL } from "../../common/services/url";

export const getProductsList = async () => {
  const list = 'list';
  return await get(`${config.URL}/${PRODUCT_URL}/${list}`);
};

export const filterProductsList = async (body) => {
  const search = "search";
  return await post(`${config.URL}/${PRODUCT_URL}/${search}`, body)
}