import React from 'react'
import {Link} from 'react-router-dom'
import '../assets/css/footer.css'

const Footer = () => {
  return (
    <div id="html-section-footer" className="html-section">
      <footer className="site-footer footer1" role="contentinfo">
        <div className="footer-top">
          <div className="footer_information">
            <div className="wrapper">
              <div className="grid">
                <div className="fi-about-block grid__item large--one-third small--one-whole medium--one-whole">
                  <div className="fi-title">
                    Контакты
                  </div>
                  <div className="fi-content">
                    <ul className="group_information">
                      <li>
                        <i className="fa fa-map-marker" /><strong>Адрес</strong>: 0027, Армения, Ереван, ул. Исакова 50/3</li>
                      <li>
                        <i className="fa fa-phone" /><strong>Тел: </strong>+374 (0)11 744080; +374 (0)12 744080</li>
                      <li>
                        <i className="fa fa-envelope" /><strong>Эл.почта: </strong>argo@argopharm.am, info@argopharm.am</li>
                    </ul>
                  </div>
                </div>
                <div className="fi-links grid__item two-thirds">
                  <div className="fi-links grid__item one-third small--one-whole medium--one-whole">
                    <div className="fi-title">
                      Информация
                    </div>
                    <div className="fi-content">
                      <ul className="grid__item">
                        <li>
                          <Link to="/aboutus"><span>О Компании</span></Link>
                        </li>
                        <li>
                          <Link to="/contact"><span>Контакты</span></Link>
                        </li>
                        <li>
                          <Link to="/drugsafety"><span>Безопасность препаратов</span></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="fi-links grid__item one-third small--one-whole medium--one-whole">
                    <div className="fi-title">КТО МЫ</div>
                    <div className="fi-content">
                      <ul className="grid__item">
                        <li>
                          <Link to="/productlist"><span>Продукты</span></Link>
                        </li>
                        <li>
                          <Link to="/blogs"><span>Мероприятия</span></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="fi-links grid__item one-third small--one-whole medium--one-whole">
                    <div className="fi-title">Оптовая торговля</div>
                    <div className="fi-content">
                      <ul className="grid__item">
                        <li>
                          <Link to="/pricelist"><span>Прайс-лист</span></Link>
                        </li>
                        <li>
                          <Link to="/sales"><span>Акции</span></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__copyright--bottom">
          <div className="wrapper">
            <div className="grid">
              <div className="grid__item one-half small--one-whole">
                <p className="copyright">
                  <small className="site-footer__copyright-content">@2021, <Link to="/">ArgoPharm.</Link> Все права защищены.</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
